import React from "react";
import { Route } from "react-router-dom";
import { PropTypes } from "prop-types";
import Card from "@cx/ui/Card";
import PageHeader from "react-bootstrap/lib/PageHeader";
import Alert from "@cx/ui/Alert";
import Header from "./Header";
import Nav from "./Nav";
import { AppContext } from "./app-context";
import { getLocaleStrings } from "../i18n/LocaleSender";
import { LanguageProvider } from "../i18n/LanguageProvider";
import {
  getUrlParameter,
  isEmptyString,
  isSameValue,
  toEmptyStringIfUndefined
} from "../commonUtil/utils/string";
import { deepCopyList } from "../commonUtil/utils/list";
import {
  // jwtRefresh,
  DEFAULT_LOCALE,
  loadUiConfig,
  jwtXsession,
  redirectToDefaultPage,
  makeSecureRestApi,
  setLocale,
  getCatalogManagerNameSpace
} from "./../api/xmmAxios";
import {
  getEnhancedDealerCode,
  isStagedCatalog
} from "../commonUtil/utils/dealer";
import {
  isEmpty,
  isArrayExist,
  doesEmpty,
  convertObjectValuesToUppercase
} from "./../commonUtil/utils/object";
import {
  exportCSVFile,
  detectBrowser,
  loadPageByWebkey
} from "./../commonUtil/utils/browser";
import LoadingIndicator from "@cx/ui/LoadingIndicator";
import ToastDefault from "./reusable/Toast";
import { Footer } from "./Footer";
import DirtyCheckPopup from "./reusable/modals/DirtyCheckPopup";
import SimpleModalDialog from "../commonUtil/dialog/SimpleModalDialog";
import "./_app.scss";
import { toast } from "@cx/ui/Toast";
import { sleep } from "./reusable/helper";
import { getMetaTagContentByName } from "../commonUtil/utils/dom";
import * as modules from "./module-routes";
import * as gtag from "./utils/gtag-manager";
import * as gtmEvents from "./utils/gtag-eventlist";
import { initAppConfig } from "./../constants/AppConfig";
import jwtDecode from "jwt-decode";
import { updateOperationStatuses } from "./reusable/operation";
import {
  globalOpsPreviewState,
  translateObjects
} from "./../constants/ModuleConstants";
import {
  handleFatalApplicationAccess,
  setTranslations,
  xlate,
  SupportedLocales
} from "../commonUtil/i18n/locales";
import { LicenseManager } from "ag-grid-enterprise";

// Add Ag-grid License here at App level
LicenseManager.setLicenseKey(
  "CompanyName=Cox Automotive Corporate Services, LLC.,LicensedGroup=Multi,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=3,LicensedProductionInstancesCount=1,AssetReference=AG-032003,SupportServicesEnd=9_November_2023_[v2]_MTY5OTQ4ODAwMDAwMA==b5cca07001950ac04847c94c8d63c8e7"
  // "CompanyName=Cox Automotive, Inc.,LicensedGroup=Multi,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=3,LicensedProductionInstancesCount=1,AssetReference=AG-018509,ExpiryDate=9_November_2022_[v2]_MTY2Nzk1MjAwMDAwMA==d11fa8520c9e0831eb796e7bb351f4f8"
  // "CompanyName=Cox Automotive Corporate Services, LLC,LicensedGroup=Multi,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=3,LicensedProductionInstancesCount=1,AssetReference=AG-011240,ExpiryDate=9_November_2021_[v2]_MTYzNjQxNjAwMDAwMA==88b28c4f8dc655f9dfb5558f5cbcffd3"
  // "Cox_Automotive_Corporate_Services,_LLC_MultiApp_3Devs_1Deployment_6_November_2020__MTYwNDYyMDgwMDAwMA==1960d8f9aeeaebf42bc858968528884d"
);

class App extends React.Component {
  static propTypes = {
    webKey: PropTypes.string,
    userName: PropTypes.string,
    locale: PropTypes.string
  };
  
  constructor(props) {
    super(props);

    // Bind AppContext handler here
    this.updateOperationList = this.updateOperationList.bind(this);
    this.updateServiceCategories = this.updateServiceCategories.bind(this);
    this.updateMakeList = this.updateMakeList.bind(this);
    this.updateDealerCatalogs = this.updateDealerCatalogs.bind(this);
    this.toggleTestName = this.toggleTestName.bind(this);
    this.discardUnsavedChanges = this.discardUnsavedChanges.bind(this);
    this.openSpeedBump = this.openSpeedBump.bind(this);
    // CustomEvent handlers
    this.handleSessioinExpired = this.handleSessioinExpired.bind(this);
    this.onFormHandle = this.onFormHandle.bind(this);
    this.updateDealerIntervals = this.updateDealerIntervals.bind(this);
    this.updateDealerMileages = this.updateDealerMileages.bind(this);
    this.updateVariantFilters = this.updateVariantFilters.bind(this);
    this.updateDealerLaborRates = this.updateDealerLaborRates.bind(this);
    this.reloadPageWithLocale = this.reloadPageWithLocale.bind(this);
    this.checkModuleAccess = this.checkModuleAccess.bind(this);
    this.saveServiceCategoryGroupsMap =
      this.saveServiceCategoryGroupsMap.bind(this);
    this.saveCategoryGroupMappings = this.saveCategoryGroupMappings.bind(this);
    this.setScopedDealers = this.setScopedDealers.bind(this);
    this.getClassWithBridgeBar = this.getClassWithBridgeBar.bind(this);
    const baseParams = this.fromQueryString();
    const webKey = baseParams && baseParams.webKey ? baseParams.webKey : "";

    this.state = {
      // global banner props
      genericMsg: xlate("xmm.portal.msg.staging_mode_in_progress"),
      statusType: "info",
      activeFormHandle: {},
      currentPage: { parentName: "dashboard", nodeName: "dashboard" },
      /* dirty speedbump */
      showDirtyModal: false,
      speedBumpCallback: null,
      isAuth: false,
      authStatus: null,
      browserSupport: true,
      authMsg: "",
      isStaging: false,
      baseLocale: DEFAULT_LOCALE,
      locale: DEFAULT_LOCALE,
      localeStrings: getLocaleStrings(DEFAULT_LOCALE),
      isLocaleLoaded: false,
      languages: [],
      supportedLocales: [],
      webKey,
      dealerCode: null,
      dealer: {
        webKey,
        dealerCode: "",
        dealerName: "",
        dmsType: "",
        defaultUnits: "miles",
        catalogAdminEmail: "",
        supportsPull: null,
        opcodeScoringEnabled: null,
        locales: {}
      },
      scopedDealers: [],
      user: {
        userName: baseParams.userName ? baseParams.userName : "",
        userId: ""
      },
      setDealer: this.setDealer,
      setUser: this.setUser,
      setScopedDealers: this.setScopedDealers,
      testName: "test",
      operationlist: [],
      liveCount: 0,
      stagedCount: 0,
      mixedPricing: false,
      allMakeList: [],
      makelist: [],
      vehicleGroupMakeList: [],
      dealerCatalogs: [],
      stagingMakeList: [],
      makeVariantMap: {},
      makeRateCodesMap: {},
      makeLaborRatesMap: {},
      dealerLaborRateCodes: [],
      serviceCategories: [],
      selectableDrivingConditions: [],
      selectableDrivingConditionsMap: {},
      contentPriceStatuses: null,
      rawDealerVariantChanges: null,
      allMakesPricingMethod: 1,
      engageEnabled: false,
      SPISQSFeatureEnabled: false,
      inspectionItemMap: {},
      dealerMakeInspectionItemMap: {},
      dealerMakeCategoryInspectionItemMap: {},
      /* save ag-grid controls to global state - operations */
      demoGridState: initAppConfig.demoGridState,
      operationsGrid: initAppConfig.operationsGrid,
      dashboard: initAppConfig.dashboard,
      opcodeValidationGrid: initAppConfig.opcodeValidationGrid,
      partsPricingGrid: initAppConfig.partsPricingGrid,
      fluidsPricingGrid: initAppConfig.fluidsPricingGrid,
      laborRatesGrid: initAppConfig.laborRatesGrid,
      payTypesGrid: initAppConfig.payTypesGrid,
      discountsGrid: initAppConfig.discountsGrid,
      packagesGrid: initAppConfig.packagesGrid,
      menuTypesGrid: initAppConfig.menuTypesGrid,
      dealerMenusGrid: initAppConfig.dealerMenusGrid,
      reportsGrid: initAppConfig.reportsGrid,
      translationGrid: initAppConfig.translationGrid,
      showSessionExpired: false,
      manualOpcodes: [],
      metaVehicleDimensionsByMake: {},
      /* page filters updated from Dashboard */
      variantFilters: null,
      // interval and mileages cache
      intervalsByMake: {},
      intervalsMapByMake: {},
      dealerMileagesByMake: {},
      dealerMileagesMapByMake: {},
      /* preview page - cached items */
      previewState: globalOpsPreviewState,
      preview: {
        search: null,
        drivingConditions: null,
        currentMileagePoint: null,
        mileagePoints: null,
        isPreview: null, // default set null
        chartResults: null,
        menuResults: null,
        sortedMenuTypes: null,
        alacarteResults: null,
        resultTabs: { selectedIndex: 0 },
        previewTabs: { selectedIndex: 0 }
      },
      previewSettings: {
        search: null,
        selectors: {
          makes: [],
          models: [],
          years: [],
          trims: [],
          engineSizeTypes: [],
          // engineTypes: [],
          // engineSizes: [],
          driveTypes: [],
          transmissionTypes: [],
          drivingConditions: []
        },
        recentlyPreview: [],
        favorites: [],
        commonVehicles: []
      },
      serviceCategoryGroupsMap: {},
      categoryGroups: [],
      categoryGroupsMap: {},
      selectedMenuType: "",
      vehicleGroupCallbackParams: {},
      tellUsMoreServiceId: "",
      serviceToSkillsMap: null,
      enableServiceContractV2: false,
      isBridgeBarEnabled: false
    };
  }

  componentDidMount() {
    loadUiConfig(this.checkModuleAccess);
    const namespace = getCatalogManagerNameSpace();
    this.initBridgeBar(namespace);
    window.addEventListener(
      "sessioinExpired",
      this.handleSessioinExpired,
      false
    );
    window.addEventListener("formHandleEvent", this.onFormHandle, false);
    window.addEventListener(
      "fatalApplicationAccess",
      this.handleFatalApplicationAccess,
      false
    );
  }

  componentWillUnmount() {
    window.removeEventListener(
      "sessioinExpired",
      this.handleSessioinExpired,
      false
    );
    window.removeEventListener("formHandleEvent", this.onFormHandle, false);
    window.removeEventListener(
      "fatalApplicationAccess",
      this.handleFatalApplicationAccess,
      false
    );
  }

  onFormHandle = event => {
    const { detail } = event;
    this.setState({
      activeFormHandle: detail
    });
  };

  initializeSession = () => {
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("pendingRefreshCalls");
    localStorage.removeItem("timeOffsetInSecs");
    // setInterval(() => {
    //   jwtRefresh(() => {
    //     console.log("Refreshed session");
    //   });
    // }, 5 * 60 * 1000);
  };

  openSpeedBump = (event, callback) => {
    // console.log("call - open dirty popup");
    this.setState(prevState => ({
      showDirtyModal: !prevState.showDirtyModal,
      speedBumpCallback: callback
    }));
  };

  closeSpeedBump = () => {
    // console.log("call - close dirty popup");
    this.setState(() => ({
      showDirtyModal: false
    }));
  };

  // This handler is part of app-context; pass callback handler from children; show speed bump when form is dirty
  discardUnsavedChanges = (event, callback, isDirtyFunc) => {
    const { activeFormHandle } = this.state;
    // const { formikProps } = this.state.activeFormHandle.props;
    // if (formikProps && formikProps.dirty) {
    const isDirty = isDirtyFunc ? isDirtyFunc() : activeFormHandle.isDirty();
    if (isDirty) {
      // show dirty popup when form is dirty
      this.openSpeedBump(event, callback);
      return false;
      // TODO - stop calling formik save
      // formikProps.handleSubmit(formikProps.values, formikProps);
    } else {
      // true - child calls to close slider
      return true;
    }
  };

  handleSessioinExpired = () => {
    const { isAuth } = this.state;
    console.log("handleSessioinExpired: isAuth=", isAuth);
    if (isAuth) {
      // session was authorized before
      setTimeout(() => {
        this.setState({ showSessionExpired: true });
      }, 500);
    } else {
      // session was never authorized
      const webkey = getUrlParameter("webKey");
      redirectToDefaultPage(webkey);
    }
  };
  // mapFatalErrors = message => {
  //   let errorMessage = message;
  //   if (message === "503 Service Temporarily Unavailable") {
  //     errorMessage = xlate("xmm.portal.errors.service_not_available");
  //   }
  //   return errorMessage;
  // }
  handleFatalApplicationAccess = event => {
    let defaultLocale = window.navigator.language;
    if (!defaultLocale) {
      defaultLocale = DEFAULT_LOCALE;
    } else {
      defaultLocale = defaultLocale.replace("-", "_");
      if (!SupportedLocales.includes(defaultLocale)) {
        defaultLocale = DEFAULT_LOCALE;
      }
    }
    this.setDefaultLocale(defaultLocale, []);
    const { messageKey, defaultMessage } = event.detail;
    // const { localeStrings } = this.state;
    const authStatus = "noaccess";
    // const authMsg = type === "dealer" ? "Unauthorized Dealer" : "Unauthorized User";
    const errorMessage = messageKey ? xlate(messageKey) : defaultMessage;
    this.setState({ authStatus, authMsg: errorMessage });
  };

  // Testing - How to update grand-parent {app-context}, from grand children {operation form, tabs }
  toggleTestName = newVal => {
    sleep(1000).then(() => {
      console.log("app context called", newVal);
      this.setState({ testName: newVal });
    });
  };
  /* Util - Updates App Context with operations ag-grid controls state */
  setOperationGridState = operationsGrid => {
    this.setState(() => {
      return {
        operationsGrid
      };
    });
  };
  setDashboardState = dashboard => {
    this.setState({
      dashboard
    });
  };
  setOpcodeValidationGridState = opcodeValidationGrid => {
    this.setState({
      opcodeValidationGrid
    });
  };
  setDemoGridState = demoGridState => {
    this.setState({
      demoGridState
    });
  };
  setPartsPricingGridState = partsPricingGrid => {
    this.setState({
      partsPricingGrid
    });
  };
  setFluidsPricingGridState = fluidsPricingGrid => {
    this.setState({
      fluidsPricingGrid
    });
  };
  setLaborRatesGridState = laborRatesGrid => {
    this.setState({
      laborRatesGrid
    });
  };
  setPayTypesGridState = payTypesGrid => {
    this.setState({
      payTypesGrid
    });
  };
  setDiscountsGridState = discountsGrid => {
    this.setState({
      discountsGrid
    });
  };
  setPackagesGridState = packagesGrid => {
    this.setState({
      packagesGrid
    });
  };
  setMenuTypesGridState = menuTypesGrid => {
    this.setState({
      menuTypesGrid
    });
  };
  setDealerMenusGridState = dealerMenusGrid => {
    this.setState({
      dealerMenusGrid
    });
  };
  setReportsGridState = reportsGrid => {
    this.setState({ reportsGrid });
  };
  setTranslationGridState = translationGrid => {
    this.setState({ translationGrid });
  };
  setScopedDealers = scopedDealers => {
    let isScopedDealer = false;
    const { webKey } = this.state;
    for (const dealer of scopedDealers) {
      if (dealer?.value?.webKey === webKey) {
        isScopedDealer = true;
        break;
      }
    }
    const authStatus = isScopedDealer ? "success" : "noaccess";
    const authMsg = isScopedDealer
      ? ""
      : this.state.localeStrings["xmm.portal.common.no_dealer_access"];

    this.setState({
      scopedDealers,
      isScopedDealer,
      authStatus,
      authMsg
    });
  };
  // Deprecated -  update vehicle details from preview page
  /*
  updateVehiclePreview = (
    vehicleDetails,
    drivingConditions,
    previewResults
  ) => {
    this.setState(prevState => {
      return { vehicleDetails, drivingConditions, previewResults };
    });
  };
  */
  // AppContext handler - fetch operations from child when restAPI returns first time
  updateOperationList = operationlist => {
    sleep(1000).then(() => {
      this.setState({ operationlist });
    });
  };
  updateInspectionItemMap = inspectionItemMap => {
    this.setState({ inspectionItemMap });
  };
  // Handler - to update variant filters called from dashboard clicks, used to launch page with these fitlers
  updateVariantFilters = variantFilters => {
    this.setState({ variantFilters });
  };
  updateServiceToSkillsMap = serviceToSkillsMap => {
    this.setState({ serviceToSkillsMap });
  };
  /* AppContext handler - update add/edit case operation to parent's state (pass as context to children)
     bulkedit case - update parent' state with list of operations
  */
  updateOperationAfterSave = (action, responseData) => {
    const { operationlist } = this.state;
    if (action === "edit") {
      // find matching record
      const recIndex = operationlist.findIndex(item =>
        isSameValue(item.serviceId, responseData.serviceId)
      );
      operationlist[recIndex] = responseData;
      // console.log("updatedItem", operationlist[recIndex]);
    } else if (action === "add") {
      operationlist.push(responseData);
    } else if (action === "bulk") {
      if (responseData.length !== 0) {
        responseData.forEach(service => {
          const recIndex = operationlist.findIndex(item =>
            isSameValue(item.serviceId, service.serviceId)
          );
          console.log(
            "App-context updated with bulkedit results",
            operationlist[recIndex]
          );
          operationlist[recIndex] = service;
        });
      }
    } else if (action === "delete") {
      const deletedOperations = responseData;
      for (let index = deletedOperations.length - 1; index >= 0; index--) {
        const service = deletedOperations[index];
        const recIndex = operationlist.findIndex(item =>
          isSameValue(item.serviceId, service.serviceId)
        );
        if (recIndex >= 0) {
          operationlist.splice(recIndex, 1);
        }
      }
    } else if (action === "updateStatuses") {
      const operationStatuses = responseData;
      for (let index = operationStatuses.length - 1; index >= 0; index--) {
        const opStatuses = operationStatuses[index];
        const recIndex = operationlist.findIndex(item =>
          isSameValue(item.serviceId, opStatuses.serviceId)
        );
        if (recIndex >= 0) {
          updateOperationStatuses(operationlist[recIndex], opStatuses);
        }
      }
    }
    // update App level state
    this.setState(() => {
      return {
        operationlist
      };
    });
  };

  replaceCatalog = (catalogList, catalog) => {
    for (let index = 0; index < catalogList.length; index++) {
      if (catalog.make === catalogList[index].make) {
        Object.assign(catalogList[index], catalog);
        // console.log(catalogList[index]);
        break;
      }
    }
    return catalogList;
  };
  // AppContext handler - to update record of dealerCatalogs; when setting page level fields are saved
  updateDealerCatalogs = catalog => {
    const { dealerCatalogs, makelist } = this.state;
    this.replaceCatalog(dealerCatalogs, catalog);
    this.replaceCatalog(makelist, catalog);
    const mixedPricing = this.setMixedPricing(dealerCatalogs);
    this.setState({
      mixedPricing,
      dealerCatalogs,
      makelist
    });
  };

  // AppContext handler - to update makelist loaded once for dealercode
  updateMakeList = newlist => {
    // console.log("appContext called to update makes");
    this.setState({ makelist: newlist });
  };
  // AppContext handler - to update service categories loaded once for locale, dealercode
  updateServiceCategories = newlist => {
    // console.log("appContext called to update categories");
    const serviceCategoryValueLabelMap = {};
    newlist.forEach(serviceCategory => {
      const { label, value } = serviceCategory;
      serviceCategoryValueLabelMap[value] = label;
    });
    this.setState({ serviceCategories: newlist, serviceCategoryValueLabelMap });
  };

  setContentPriceStatuses = contentPriceStatuses => {
    this.setState({ contentPriceStatuses });
  };

  setManualOpcodes = manualOpcodes => {
    this.setState({ manualOpcodes });
  };
  setCancelOpcodeValidationDate = cancelOpcodeValidationDate => {
    this.setState({ cancelOpcodeValidationDate });
  };

  // This way any UserContext.Consumer could access the setter to change the user.
  setUser = user => {
    this.setState({ user, isAuth: true });
  };
  setDealer = dealer => {
    this.processLocales(dealer);
    this.setState({ dealer, dealerCode: dealer.dealerCode });
  };

  isDMSPlus = () => {
    const { dealer, isDMSHybridMigrationEnabled, locale } = this.state;
    const { dmsType } = dealer;
    this.setState({
      enableDMSPlusContent:
        locale === "en_US" &&
        (dmsType === "DMS+" || isDMSHybridMigrationEnabled)
    },
    () => {
      this.initDealerSequenceNumber();
    });
  };
  processLocales(dealer) {
    let localeList =
      dealer.locale && dealer.locale.length !== 0 ? dealer.locale[0] : [];
    if (!Array.isArray(localeList)) {
      localeList = [localeList];
    }
    const { defaultLocale } = dealer;
    this.setDefaultLocale(defaultLocale, localeList);
  }
  convertToLanguageCountry(locale) {
    let langPrefix = locale.substr(0, 2);
    const countryPrefix = locale.substr(3, 2);
    if (langPrefix === "en") {
      langPrefix = "English";
    } else if (langPrefix === "fr") {
      langPrefix = "Français";
    } else if (langPrefix === "es") {
      langPrefix = "Español";
    }
    return `${langPrefix} (${countryPrefix})`;
  }
  setDefaultLocale(defaultLocale, localeList) {
    // get a list of dealer supported locales
    const locales = localeList.map(locale => {
      return locale.localeCode;
    });
    // e.g locales = [ "en_CA", "fr_CA" ]
    let languages = [];
    let hasNonbaseLocale = false;
    if (locales && locales.length !== 0) {
      languages = locales.map(lc => {
        const label = this.convertToLanguageCountry(lc);
        const value = lc;
        if (lc.indexOf("fr_") !== -1) {
          hasNonbaseLocale = true;
        }
        return { label, value };
      });
    }

    // get dealer default locale, it is not specified and there is no supported locale, then default to en_US
    const baseLocale = !defaultLocale
      ? locales.length !== 0
        ? locales[0]
        : DEFAULT_LOCALE
      : defaultLocale;

    // default to base locale before processing the user locale from browser
    let currentLocale = baseLocale;

    // get locale query parameter if it is passed
    const baseParams = this.fromQueryString();
    const localeQueryParam = baseParams.locale ? baseParams.locale : null;

    // default to browser locale if query parameter is not passed
    let userLocale = localeQueryParam
      ? localeQueryParam
      : window.navigator.language;

    // check to make sure userLocale supported by dealer
    if (userLocale) {
      // convert user locale separator from hyphen to underscore
      userLocale = userLocale.replace("-", "_");
    }
    // update currentLocale to userLocale if it is supported by dealer
    if (userLocale) {
      if (locales.length === 0) {
        // if supported locales are empty then default it to baseLocale
        currentLocale = baseLocale;
      } else {
        if (locales.includes(userLocale)) {
          // userLocale matches one of the supported locales
          currentLocale = userLocale;
        } else if (locales.includes(baseLocale)) {
          // baseLocale matches one of the supported locales
          currentLocale = baseLocale;
        } else {
          // neither baseLocale or userLocale matches any of the supported locale
          currentLocale = locales[0];
        }
      }
    }

    // save locale to state; make sure baseLocale and locale can't be different countries: e.g. en_CA vs en_US
    const locale = currentLocale;
    // const locale =
    //   baseLocale !== currentLocale && currentLocale.indexOf("en_") !== -1
    //     ? baseLocale
    //     : currentLocale;
    // set localeStrings
    let localeStrings = getLocaleStrings(locale);
    // add "locale" to localeStorage
    setLocale(locale);

    // TODO: Removed the if block once translation is done
    if (locale !== DEFAULT_LOCALE) {
      const defaultLocaleStrings = getLocaleStrings(DEFAULT_LOCALE);
      // this.exportLocaleStringsToCsvFormat(defaultLocaleStrings);
      // if not default locale then copy uppercase values before apply the translation
      const baseLocaleStrings =
        convertObjectValuesToUppercase(defaultLocaleStrings);
      localeStrings = Object.assign(baseLocaleStrings, localeStrings);
    }
    // set translation strings into translations list in locales.js
    setTranslations(localeStrings, locale);
    this.setState({
      baseLocale,
      languages,
      hasNonbaseLocale,
      locale,
      supportedLocales: locales,
      localeStrings
    });
    // Note: update constant objects with localeStrings values
    translateObjects(localeStrings);
  }
  exportLocaleStringsToCsvFormat(localeStrings) {
    const csvStrings = Object.keys(localeStrings).map(key => {
      return `${key}, ${localeStrings[key]}`;
    });
    exportCSVFile(null, csvStrings, "Locale Strings in English");
    console.log("csvStrings", csvStrings);
  }
  /* This method returns query params from window.location */
  fromQueryString = () => {
    const baseParams = {};
    baseParams.locale = getUrlParameter("locale");
    baseParams.webKey = getUrlParameter("webKey");
    baseParams.userName = getUrlParameter("userName");
    baseParams.launchedFrom = getUrlParameter("launchedFrom");
    baseParams.dealerCode = "";
    // baseParams.userId = getUrlParameter("userId");
    return baseParams;
  };

  /* This call check if user is authorized to access page */
  checkModuleAccess() {
    this.initializeSession();

    const webkey = getUrlParameter("webKey");
    const pt = getUrlParameter("pt");
    if (pt === "cm") {
      const locale = getUrlParameter("locale");
      loadPageByWebkey(webkey, locale);
      return;
    }

    const isAuth = false;
    let msg = "";
    // const hasAccess = this.checkBrowser(); // uncomment to check browser support
    const hasAccess = true;
    if (hasAccess) {
      if (!isEmpty(webkey)) {
        jwtXsession(
          data => {
            const decodedAccessToken =
              data && data.accessToken ? jwtDecode(data.accessToken) : null;
            const userName = decodedAccessToken
              ? decodedAccessToken.sub
              : this.state.user.userName;
            const userId = decodedAccessToken
              ? decodedAccessToken.user_id
              : this.state.user.userId;
            // console.log(
            //   "callback data from jwtXsession",
            //   data,
            //   decodedAccessToken
            // );
            this.setState(
              {
                browserSupport: hasAccess,
                authStatus: "success",
                authMsg: "",
                user: { ...this.state.user, userName, userId }
              },
              () => {
                this.loadDealerSettings();
              }
            );
          },
          error => {
            this.setState({
              authStatus: "noaccess",
              browserSupport: hasAccess,
              isAuth: false,
              authMsg: error.message
            });
          }
        );
      } else {
        // msg = "You are not authorized to access this page.";
        msg = this.state.localeStrings["xmm.portal.common.auth_error_msg"];
        this.setState({
          browserSupport: hasAccess,
          isAuth,
          authStatus: "noaccess",
          authMsg: msg
        });
      } // auth else
    } else {
      // msg = "Please use Google Chrome, Firefox, Apple Safari to access this application.";
      msg = msg =
        this.state.localeStrings["xmm.portal.common.browser_support_msg"];
      this.setState({
        browserSupport: false,
        authStatus: "error",
        isAuth,
        authMsg: msg
      });
    }
  }
  /* optional check if we want to display unsupported browser msg */
  checkBrowser() {
    const browserName = detectBrowser();
    let access = true;
    if (browserName === "Microsoft") {
      access = false;
    }
    return access;
  }
  // loadUiConfig() {
  //   const { origin } = window.location;
  //   makeSecureRestApi(
  //     {
  //       url: `${origin}/data/UIConfig.json`,
  //       method: "get"
  //     },
  //     response => {
  //       console.log(response);
  //       initializeUiConfig(response);
  //       this.checkModuleAccess();
  //     },
  //     error => {
  //       toast.error("Error loading UiConfig", {
  //         closeOnClick: true
  //       });
  //     }
  //   );
  // }
  hasBMWorMINI(dealerCatalogs) {
    for (let index = 0; index < dealerCatalogs.length; index++) {
      const catalog = dealerCatalogs[index];
      const { make } = catalog;
      if (make === "BMW" || make === "MINI") {
        return true;
      }
    }
    return false;
  }
  getBMWValueServicesEnabled() {
    const { dealerCode, localeStrings } = this.state;
    makeSecureRestApi(
      {
        url: `/ops/proxyapi/oeproxy/rest/xmmdealer/dealerProperty/${dealerCode}/property/BMW_VALUE_SERVICES_ENABLED`,
        method: "get"
      },
      response => {
        const isBmwValueServicesEnabled = response.data === "Y";
        this.setState({ isBmwValueServicesEnabled });
      },
      error => {
        const msg = error["message"]
          ? error.message
          : localeStrings[
              "xmm.portal.errors.fetch_bmw_value_service_enabled_property"
            ];
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
  }
  initDealerSequenceNumber() {
    const { dealerCode, localeStrings, enableDMSPlusContent } = this.state;
    if (enableDMSPlusContent) {
      makeSecureRestApi(
        {
          url: `ops/proxyapi/oeproxy/rest/internal/dealerSvcTypeSeq/initDealerSvcTypeSeqNum/${dealerCode}`,
          method: "get"
        },
        response => {
          console.log(response);
        },
        error => {
          const msg = error["message"]
            ? error.message
            : localeStrings[
                "xmm.portal.errors.fetch_bmw_value_service_enabled_property"
              ];
          toast.error(msg, {
            closeOnClick: true
          });
        }
      );
    }
  }
  getDMSHybridMigration() {
    const { dealerCode, localeStrings } = this.state;
    makeSecureRestApi(
      {
        url: `/ops/proxyapi/oeproxy/rest/xmmdealer/dealerProperty/${dealerCode}/property/DMS_Hybrid_Migration`,
        method: "get"
      },
      response => {
        const isDMSHybridMigrationEnabled = response.data === "Y";
        this.setState({ isDMSHybridMigrationEnabled });
        this.isDMSPlus();
      },
      error => {
        const msg = error["message"]
          ? error.message
          : localeStrings[
              "xmm.portal.errors.fetch_bmw_value_service_enabled_property"
            ];
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
  }
  getUseServiceContractV2Enabled() {
    const {localeStrings, enableServiceContractV2 } = this.state;
    makeSecureRestApi(
      {
        url: "ops/proxyapi/oeproxy/rest/internal/apiSettings/getXMMConfigValue?keyName=useServiceContractV2",
        method: "get"
      },
      response => {
        let enableServiceContractV2; 
        if (response.data && response.data.useServiceContractV2 ) {
          enableServiceContractV2 = response.data.useServiceContractV2 === "Y";
        } else {
          enableServiceContractV2 = false;
        }
        this.setState({ enableServiceContractV2 });
      },
      error => {
        const msg = error["message"]
          ? error.message
          : localeStrings[
              "xmm.portal.errors.fetch_use_service_contract_flag"
            ];
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
  }
  getIsBridgeBarEnabled(namespace) {
    const {localeStrings } = this.state;
    makeSecureRestApi(
      {
        url: "ops/proxyapi/oeproxy/rest/internal/apiSettings/getXMMConfigValue?keyName=enableBridgeBarIntoCatalogManager",
        method: "get"
      },
      response => {
        const uuid= this.uuidv4();
        const {bbEnvType,solutionGroupCode} = this.getBbEnvTypeSGC(namespace);
        let isBridgeBarEnabled; 
        if (response.data && response.data.enableBridgeBarIntoCatalogManager ) {
          isBridgeBarEnabled = response.data.enableBridgeBarIntoCatalogManager === "Y";
        } else {
          isBridgeBarEnabled = false;
        }
        this.setState({ isBridgeBarEnabled }, ()=> {
          if (this.state.isBridgeBarEnabled) {
            this.loadBridgeBar(uuid,bbEnvType,solutionGroupCode);
          }
        });
      },
      error => {
        const msg = error["message"]
          ? error.message
          : localeStrings[
              "xmm.portal.errors.fetch_bridge_bar_flag"
            ];
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
  }
  // This should be called when dealer data ready.
  // Method returns supported makes for given dealer
  loadSupportedMakes = dealerCodeVal => {
    // get the plmp dealerCode if the dealerCodeVal is staging
    // plmp dealerCode is needed to call dealerCatalog for staging dealerCode
    const { dealerCode, isStaging } = getEnhancedDealerCode(dealerCodeVal);
    const { localeStrings } = this.state;
    makeSecureRestApi(
      {
        url: "/ops/proxyapi/ddsproxy/rest/proc/dealerCatalog",
        method: "get",
        data: {},
        params: { dealerCode }
      },
      response => {
        let makelist = [];
        const vehicleGroupMakeList = [];
        const stagingMakeList = [];
        const makeVariantMap = {};
        let liveCount = 0;
        let stagedCount = 0;

        if (response) {
          const datalist = response;
          if (datalist && datalist.length > 0) {
            let hasAny = false;

            datalist
              // .filter(m => m.make !== "ANY" && m.isStaging === 0)
              .forEach(m => {
                if (m.make === "ANY") {
                  hasAny = true;
                  m.label = m.make;
                  m.value = m.make;
                  vehicleGroupMakeList.push(m);
                } else if (m.isStaging === 0) {
                  m.label = m.make;
                  m.value = m.make;
                  vehicleGroupMakeList.push(m);
                  makelist.push(m);
                } else if (m.isStaging === 1) {
                  m.label = m.make;
                  m.value = m.make;
                  m.dealerCode = m.useDealerCode;
                  stagingMakeList.push(m);
                }

                makeVariantMap[m.make] = m;
              });
            liveCount = makelist.length + (hasAny ? 1 : 0);
            stagedCount = stagingMakeList.length;
            if (isStaging) {
              makelist = stagingMakeList;
            }
          }
          console.log("Supported makes in [App.js]", makelist);
        }
        const allMakeList = [...makelist];
        allMakeList.splice(0, 0, {
          label: xlate("xmm.portal.common.all_makes"),
          value: "all",
          make: "all",
          dealerCode,
          pricingMethod: "",
          priorityOpcodeStrings: "",
          variant: "all"
        });
        const dealerCatalogs = this.getDealerCatalogs(
          makelist,
          makeVariantMap,
          isStaging
        );
        // reload page to staging webkey if plmp dealer has no live catalogs
        if (liveCount === 0 && stagedCount > 0 && !isStaging) {
          // switch to staging catalog since there is no catalog in enhanced mode
          const locale = getUrlParameter("locale");
          const webKey = getUrlParameter("webKey");
          loadPageByWebkey(webKey, locale, true);
        } else {
          const mixedPricing = this.setMixedPricing(makelist);

          this.setState(
            {
              liveCount,
              stagedCount,
              allMakeList,
              makelist,
              vehicleGroupMakeList,
              dealerCatalogs,
              stagingMakeList,
              makeVariantMap,
              mixedPricing
            },
            () => {
              this.loadCatalogNotifications(dealerCode, allMakeList);
            }
          );
        }
        if (this.hasBMWorMINI(makelist)) {
          // get BMW Value Services Program
          this.getBMWValueServicesEnabled();
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : localeStrings["xmm.portal.errors.fetch_catalogs"];
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
  };
  setMixedPricing(makelist) {
    let mixedPricing = false;
    let pricingMethod = null;
    for (let index = 0; index < makelist.length; index++) {
      const mObj = makelist[index];
      // Note: Donot consider pricingMethod of "ANY" make
      if (mObj.make !== "ANY") {
        if (!pricingMethod) {
          pricingMethod = mObj.pricingMethod;
        } else if (pricingMethod !== mObj.pricingMethod) {
          mixedPricing = true;
          break;
        }
      }
    }
    return mixedPricing;
  }
  // Deep clone supported makes for settings page
  getDealerCatalogs(makelist, makeVariantMap, isStaging) {
    const rawCatalogs = deepCopyList(makelist);
    // append "ANY" catalog with Live catalogs only
    if (
      !isStaging &&
      !isEmpty(makeVariantMap) &&
      !isEmpty(makeVariantMap["ANY"])
    ) {
      const anyMake = makeVariantMap["ANY"];
      anyMake.label = anyMake.make;
      anyMake.value = anyMake.make;
      rawCatalogs.splice(0, 0, anyMake);
    }
    console.log("[App.js] dealerCatalogs", rawCatalogs);
    return rawCatalogs;
  }
  /* This call returns All Makes notifications; used in Dashboard */
  loadCatalogNotifications(dealerCode, allMakeList) {
    const reqParams = {
      make: "",
      dealerCode,
      variant: ""
    };
    makeSecureRestApi(
      {
        url: "/ops/proxyapi/ddsproxy/rest/proc/getDealerVariantChanges",
        method: "get",
        data: {},
        params: reqParams
      },
      response => {
        const list = this.filterMakeByPricingMethod(allMakeList);
        this.setState(
          {
            allMakesPricingMethod: list.length > 0 ? 1 : 0,
            rawDealerVariantChanges: response
          },
          () => {
            window.dispatchEvent(
              new CustomEvent("getDealerVariantChanges", {
                detail: {},
                bubbles: true,
                cancelable: true
              })
            );
          }
        );
      },
      error => {
        toast.error(error.message);
      }
    );
  }
  filterMakeByPricingMethod(list) {
    const filterList = list.filter(function (obj) {
      return !doesEmpty(obj.pricingMethod) && obj.pricingMethod === 1;
    });
    // console.log("How many makes has calci flow", filterList);
    return filterList;
  }
  /* Convert labor rates per dealerCode into Map */
  loadLaborRates(dealerCode) {
    const restUrl = `/ops/proxyapi/ddsproxy/rest/table/dealerLaborRateCode`;
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json"
    };
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get",
        data: {},
        params: { dealerCode },
        headers
      },
      data => {
        if (data) {
          this.convertLaborRates(data);
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : xlate("xmm.portal.errors.fetch_dealer_data");
        toast.error(msg, {
          closeOnClick: true
        });
      }
    );
  }
  convertLaborRates(datalist) {
    const makeLaborRatesMap = {};
    const makeRateCodesMap = {};
    if (datalist && datalist.length > 0) {
      const uniqueMakes = [...new Set(datalist.map(x => x.make))];
      uniqueMakes.forEach(m => {
        if (m) {
          const filterList = this.filterLaborRatesByMake(m, datalist);
          const rateList = this.formatLaborRatesList(filterList);
          makeLaborRatesMap[m.toString()] = filterList;
          makeRateCodesMap[m.toString()] = rateList;
        }
      });
    }
    console.log("[App.js] makeRateCodesMap", makeRateCodesMap);
    this.setState({
      dealerLaborRateCodes: datalist,
      makeLaborRatesMap,
      makeRateCodesMap
    });
  }

  filterLaborRatesByMake(make, list) {
    const filterList = list.filter(function (obj) {
      return obj.make === make;
    });
    // console.log("labor rates for make", make, filterList);
    return filterList;
  }

  formatLaborRatesList(filterList) {
    const rateList = [];
    if (filterList.length > 0) {
      filterList.forEach(item => {
        if (!doesEmpty(item.dealerLaborRateCodeId)) {
          const code = {
            value: item.dealerLaborRateCodeId.toString(),
            label: doesEmpty(item.description) ? "" : item.description,
            laborRateCode: doesEmpty(item.laborRateCode)
              ? ""
              : item.laborRateCode,
            make: item.make
          };
          rateList.push(code);
        }
      });
    }
    return rateList;
  }
  updateDealerLaborRates(mode, laborRateCode) {
    const { dealerLaborRateCodes } = this.state;
    if (mode === "add") {
      dealerLaborRateCodes.push(laborRateCode);
    } else {
      const index = dealerLaborRateCodes.findIndex(
        item =>
          item.dealerLaborRateCodeId === laborRateCode.dealerLaborRateCodeId
      );
      if (index !== -1) {
        if (mode === "delete") {
          dealerLaborRateCodes.splice(index, 1);
        } else {
          dealerLaborRateCodes[index] = laborRateCode;
        }
      }
    }
    if (mode === "add" || mode === "delete") {
      this.convertLaborRates(dealerLaborRateCodes);
    }
  }
  // App level - This call returns User object with userName
  getUserData = data => {
    let userName = this.state.user.userName;
    /* Note: get userName as parameter in window.location for local Dev */
    const hostname = window.location.hostname;
    if (hostname === "localhost") {
      userName = getUrlParameter("userName");
    }
    const restUrl = "/ops/userapi/getUserDetails/" + userName;
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get",
        data: {},
        params: {}
      },
      response => {
        if (response) {
          if (!isArrayExist(response) && !isEmpty(response)) {
            if (!isEmpty(response.data)) {
              const { dealerCode } = data.dealer;
              const user = response.data;
              user.userId = user.id;
              user.userName = user.username;
              this.setUser(user);
              // calls these apis only if user is authenticated
              this.loadScopedDealers(user);
              this.loadPartManufacturerCodes();
              this.loadSelectableDrivingConditions(dealerCode);
              this.loadLaborRates(dealerCode);
              this.loadSupportedMakes(dealerCode);
              this.loadServiceCategories();
              this.getTellUsMoreService(dealerCode);
              this.getEngageEnabled(dealerCode);
              this.getSPISQSFeatureEnabled(dealerCode);
              // initialize GTM dataLayer one time when User Object is ready
              this.initGTagDataLayer();
            } else {
              const config = {
                method: "get",
                url: restUrl,
                statusCode: 200
              };
              gtag.trackUnknowError(response, config);
            }
          }
        }
      },
      error => {
        const messageKey = "xmm.portal.errors.get_user_detail";
        const defaultMessage = error.response.data;
        handleFatalApplicationAccess({ messageKey, defaultMessage });
      }
    );
  };
  // App level - returns dealer settings object using webKey
  loadDealerSettings = () => {
    const webKey = getUrlParameter("webKey");
    const isStaged = getUrlParameter("isStaged") === "1";
    makeSecureRestApi(
      {
        url: "/ops/dealerapi/settings/" + webKey,
        method: "get",
        data: {},
        params: {}
      },
      data => {
        if (data && data.dealer) {
          if (isStaged) {
            data.dealer.dealerCode = data.dealer.dealerCode + "__STG_";
          }
          data.dealer.laborTimeMarkupPercent = toEmptyStringIfUndefined(
            data.dealer.laborTimeMarkupPercent
          );
          this.setDealer(data.dealer);
          this.getUserData(data);
          this.getDMSHybridMigration();
          this.getUseServiceContractV2Enabled();
        } else {
          toast.error(xlate("xmm.portal.errors.get_dealer_settings"));
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : xlate("xmm.portal.errors.get_dealer_settings");
        toast.error(msg, {
          autoClose: 2000,
          closeOnClick: true
        });
        // if Dealer settings failed, still call below rest APIs
        // this.loadServiceCategories();
      }
    );
  };

  // App level - get all service categories per locale; store in global context
  loadServiceCategories = () => {
    const locale = getUrlParameter("locale");
    makeSecureRestApi(
      {
        url: "/ops/proxyapi/ddsproxy/rest/proc/xmmFindAllServiceCategories",
        method: "get",
        data: {},
        params: { locale }
      },
      response => {
        const categorylist = [
          // { nodeName: null, label: "-- None --", value: "0" }
        ];
        if (response && isArrayExist(response) && response.length > 0) {
          const datalist = response;
          datalist.map(category =>
            categorylist.push({
              nodeName: category.nodeName,
              label: category.defaultServiceName,
              value: category.serviceCategoryId + ""
            })
          );
        }
        // console.log("Service categories in [App.js]", categorylist);
        this.updateServiceCategories(categorylist);
      },
      error => {
        const msg = error["message"]
          ? error.message
          : xlate("xmm.portal.errors.fetch_service_categories");
        toast.error(msg);
      }
    );
  };
  loadSelectableDrivingConditions = dealerCode => {
    makeSecureRestApi(
      {
        url: "/ops/proxyapi/ddsproxy/rest/proc/getSelectableDrivingConditions",
        method: "get",
        params: { dealerCode }
      },
      selectableDrivingConditions => {
        if (
          selectableDrivingConditions &&
          isArrayExist(selectableDrivingConditions) &&
          selectableDrivingConditions.length > 0
        ) {
          const selectableDrivingConditionsMap = {};
          selectableDrivingConditions.forEach(item => {
            selectableDrivingConditionsMap[item.make] = item;
          });
          this.setState({
            selectableDrivingConditions,
            selectableDrivingConditionsMap
          });
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : xlate("xmm.portal.errors.fetch_selectable_driving_conditions");
        toast.error(msg);
      }
    );
  };

  // App level - find TellUsMore service for that dealer
  getTellUsMoreService = dealerCode => {
    makeSecureRestApi(
      {
        url: "/ops/proxyapi/ddsproxy/rest/proc/getTellUsMoreService",
        method: "get",
        data: {},
        params: { dealerCode }
      },
      response => {
        let tellUsMoreServiceId = "";
        if (response && response.length > 0) {
          const datalist = response[0];
          tellUsMoreServiceId = datalist.tellUsMoreServiceId;
        }
        this.setState({ tellUsMoreServiceId });
      },
      error => {
        const msg = error["message"]
          ? error.message
          : xlate("xmm.portal.errors.fetch_service_categories");
        toast.error(msg);
      }
    );
  };

  // App level - find TellUsMore service for that dealer
  getEngageEnabled = dealerCode => {
    makeSecureRestApi(
      {
        url: `/ops/proxyapi/oeproxy/rest/xmmdealer/dealerProperty/${dealerCode}/property/ENGAGE8_ENABLED`,
        method: "get"
      },
      response => {
        let engageEnabled = false;
        if (response && response.data === "Y" && response.success) {
          engageEnabled = true;
        }
        this.setState({ engageEnabled });
      },
      error => {
        const msg = error["message"]
          ? error.message
          : xlate("xmm.portal.errors.fetch_service_categories");
        toast.error(msg);
      }
    );
  };

  /**
   * @param {string} dealerCode
   * @param {string} featureFlag
   *
   * Feature flag fetch request for CDK dealership
   */
  getSPISQSFeatureEnabled = (dealerCode, featureFlag = "SPI_SQS_ENABLED") => {
    makeSecureRestApi(
      {
        url: `/ops/proxyapi/oeproxy/rest/xmmdealer/dealerProperty/${dealerCode}/property/${featureFlag}`,
        method: "get"
      },
      response => {
        const { data, success } = response || {};
        this.setState({
          SPISQSFeatureEnabled: success && data === "Y" ? true : false
        });
      },
      error => {
        const msg = error["message"]
          ? error.message
          : xlate("xmm.portal.errors.fetch_dealer_data");
        toast.error(msg);
      }
    );
  };

  loadPartManufacturerCodes() {
    makeSecureRestApi(
      {
        url: "/ops/proxyapi/ddsproxy/rest/table/oemCodes",
        method: "get"
      },
      datalist => {
        if (datalist && datalist.length !== 0) {
          const partManufacturerCodeMap = {};
          datalist.forEach(pmc => {
            const { make } = pmc;
            partManufacturerCodeMap[make] = pmc;
          });
          this.setState({ partManufacturerCodeMap });
        }
      },
      () => {
        toast.error("Failed to load Part Manufacturer Codes.");
      }
    );
  }

  loadScopedDealers = user => {
    // Is there a React-y way to avoid rebinding `this`? fat arrow?
    const { userId } = user;
    const restUrl = `/ops/dealerapi/dealerList/${userId}`;
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get",
        data: {}
      },
      response => {
        if (response) {
          let scopedDealers = response;
          scopedDealers.sort(this.sortingDealers);
          scopedDealers = scopedDealers.map(dealer => ({
            value: dealer,
            label: dealer.dealerName
          }));
          this.setScopedDealers(scopedDealers);
        }
      },
      error => {
        console.error("Error to fetch scoped-dealers", error);
      }
    );
  };

  sortingDealers(a, b) {
    if (!isEmpty(a) && !isEmpty(b)) {
      if (!isEmptyString(a.dealerName) && !isEmptyString(b.dealerName)) {
        if (a.dealerName < b.dealerName) {
          return -1;
        }
        if (a.dealerName > b.dealerName) {
          return 1;
        }
      }
    }
    return 0;
  }

  /*
     Initialize GTM dataLayer first time with unique account/application details at index.html
     Push event data, userTimings, pageviews to GTM dataLayer
    */
  initGTagDataLayer() {
    const { user, dealer, locale } = this.state;
    const isReadOnly = false; // full access user
    const env = gtag.getEnvironment();
    let trainingRole = undefined;
    let userDomain = undefined;
    let dmsType = undefined;
    let schemaName = undefined;
    const webKeyVal = getUrlParameter("webKey");
    let branch = getMetaTagContentByName("xtime:gitBranch");
    let build = getMetaTagContentByName("xtime:buildNumber");
    branch = branch.indexOf("%REACT_APP") !== -1 ? undefined : branch;
    build = build.indexOf("%REACT_APP") !== -1 ? undefined : "-" + build;
    const appVersion = branch + build;
    let dealerCountry = undefined;
    // console.log("gtag", env, user, branch, build);

    // Only for "automationuser" user, push trainingRole as "AUTOMATION" ( to differ from other real-time users)
    if (Object.keys(user).length > 0) {
      if (user.username && user.username === "automationuser") {
        trainingRole = "AUTOMATION";
      }
      /* Push GA dimension "trainingRole" to differnciate dealers from QA automation / xtime support team will be 'XTEMPLOYEE' */
      if (user.trainingRole) {
        trainingRole = user.trainingRole;
      }
      // Push GA dimension userDomain eg: "xtime.com"
      if (user.email) {
        let email = user.email.toString();
        email = email.split("@");
        userDomain = email[1];
      }
    }
    // check for dealer object to update GTM
    if (Object.keys(dealer).length > 0) {
      dmsType = !dealer.dmsType ? undefined : dealer.dmsType;
      schemaName = !dealer.schemaName
        ? undefined
        : dealer.schemaName.toLowerCase();
      dealerCountry = !dealer.dealerCountryCode
        ? undefined
        : dealer.dealerCountryCode;
    }

    // Cox related common details
    const eventObj = {
      common: {
        dataLayerVersion: 1,
        user: {
          bridgeUser: undefined,
          applicationUser: user.username,
          userType: trainingRole,
          isInternalUser: true
        },
        application: {
          businessUnitName: "Xtime",
          name: "CommonCatalog",
          version: !appVersion ? undefined : appVersion,
          environment: env,
          isProduction: env === "production" ? true : false
        },
        context: {
          dealershipId: dealer.dealerCode ? dealer.dealerCode : undefined,
          dealershipName: dealer.dealerName ? dealer.dealerName : undefined
        }
      },
      // Push application values like userId, role, enviornment, webKey to dataLayer for GA
      appName: "CommonCatalog",
      appVersion: !appVersion ? undefined : appVersion,
      environment: env,
      user: user.userId,
      dealerCode: !dealer.dealerCode ? undefined : dealer.dealerCode,
      dmsType,
      schemaName,
      trainingRole,
      userDomain,
      readOnly: isReadOnly,
      isMember: undefined,
      webKey: !webKeyVal ? undefined : webKeyVal,
      dealerCountry,
      locale
    };
    this.pushGTagAppDetails(eventObj);
    // Track catalog manager launcher from parent app
    const launchedFrom = getUrlParameter("launchedFrom");
    gtmEvents.trackLaunchHub(launchedFrom);
  }
  /* For GTM session data, we want to initialize the data layer, fire off a page view, and then collect User events */
  pushGTagAppDetails(eventObj) {
    gtag.pushDimensions(eventObj);
    window.dataLayer.push({
      event: "dataLayerReady",
      dataLayerReady: true
    });
    // This GTM event moved to switch event handler
    // const isStaged = getUrlParameter("isStaged") === "1";
    // gtmEvents.trackCatalogs(!isStaged ? "live" : "staging");
    console.log("First dataLayer", window.dataLayer);
  }
  updateDealerIntervals = (make, intervals, intervalsMap) => {
    console.log(make, intervals, intervalsMap);
    const { intervalsByMake, intervalsMapByMake } = this.state;
    intervalsByMake[make] = intervals;
    intervalsMapByMake[make] = intervalsMap;
    this.setState({ intervalsByMake, intervalsMapByMake });
  };
  updateDealerMileages = (make, dealerMileages, dealerMileagesMap) => {
    const { dealerMileagesByMake, dealerMileagesMapByMake } = this.state;
    dealerMileagesByMake[make] = dealerMileages;
    dealerMileagesMapByMake[make] = dealerMileagesMap;
    this.setState({ dealerMileagesByMake, dealerMileagesMapByMake });
  };
  hasCalculatedPricing(makelist, makeVariantMap) {
    for (let index = 0; index < makelist.length; index++) {
      const catalog = makelist[index];
      const { baseLocale, pricingMethod } = catalog;
      if (pricingMethod === 1 && baseLocale === "en_US") {
        return true;
      }
    }
    if (makeVariantMap["ANY"]) {
      const { pricingMethod, baseLocale } = makeVariantMap["ANY"];
      return pricingMethod === 1 && baseLocale === "en_US";
    }
    return false;
  }
  /* relod application with selected locale */
  reloadPageWithLocale = selectedLocale => {
    const { isStaging, webKey, localeStrings } = this.state;
    // Note: we need to update localStorage before redirecting page. Since APIs are using locale from localstorage
    setLocale(selectedLocale, localeStrings);
    loadPageByWebkey(webKey, selectedLocale, isStaging);
  };
  saveServiceCategoryGroupsMap(serviceCategoryGroupsMap) {
    this.setState({ serviceCategoryGroupsMap });
  }
  savePreviewState = previewState => {
    console.log("[App.js] savePreviewState", previewState);
    this.setState({ previewState });
  };
  saveCategoryGroupMappings(categoryGroups, categoryGroupsMap) {
    // const { previewState } = this.state;
    // previewState.categoryGroups = categoryGroups;
    // previewState.categoryGroupsMap = categoryGroupsMap;
    this.setState({ categoryGroups, categoryGroupsMap });
  }
  initBridgeBar(namespace){
    this.getIsBridgeBarEnabled(namespace);    
  }
  // Function to initialize the bridge-bar component once the script has loaded
  loadBridgeBar = (uuidv4, bbEnvType,solutionGroupCode) => {
    (function () {
      var bbEl = window.document.querySelector('bridge-bar');
      
      bbEl.addEventListener('initialized', function() {
          bbEl.bb.render({
              env: bbEnvType,
              solutionGroupCode: solutionGroupCode,
              sessionId: uuidv4
          });
      });
    }());
  };
  getClassWithBridgeBar = (pseudoClass)  => {
    const { isBridgeBarEnabled } = this.state;
    let classWithBridgebar, classWithouBridgebar;

    if (pseudoClass === "xmm-slider") {
      classWithBridgebar = "xmm-fixed-slider-with-bridgebar";
      classWithouBridgebar = "xmm-fixed-slider";
    } else if (pseudoClass === "xmm-slider-1") {
      classWithBridgebar = "xmm-fixed-slider-with-bridgebar";
      classWithouBridgebar = "";
    } else if (pseudoClass === "ag-grid-container") {
      classWithBridgebar = "ag-grid-container-with-bridgebar ag-theme-balham";
      classWithouBridgebar = "ag-grid-container ag-theme-balham";
    } else if (pseudoClass === "ag-grid-container-1") {
      classWithBridgebar = "ag-grid-container-with-bridgebar-1 ag-theme-balham";
      classWithouBridgebar = "ag-grid-container ag-theme-balham";
    } else if (pseudoClass === "xmm-manage-vehicle") {
      classWithBridgebar = "xmm-manage-vehicle-with-bridgebar";
      classWithouBridgebar = "xmm-manage-vehicle";
    } else if (pseudoClass === "xmm-validate-opcode") {
      classWithBridgebar = "xmm-validate-opcode-modal-with-bridgebar";
      classWithouBridgebar = "xmm-validate-opcode-modal";
    } else if (pseudoClass === "xmm-card-container") {
      classWithBridgebar = "xmm-card-container-with-bridgebar";
      classWithouBridgebar= "xmm-card-container";
    } else if (pseudoClass === "xmm-preview-modal") {
      classWithBridgebar = "xmm-preview-modal-with-bridgebar";
      classWithouBridgebar= "xmm-preview-modal";
    } else if (pseudoClass === "xmm-preview-modal-1") {
      classWithBridgebar = "xmm-preview-modal-with-bridgebar";
      classWithouBridgebar= "";
    } else {
      classWithBridgebar = pseudoClass;
      classWithouBridgebar = pseudoClass;
    }

    return isBridgeBarEnabled?classWithBridgebar:classWithouBridgebar;
  };
  // Generate a UUIDv4 to use as a unique session ID
  uuidv4() {
        return "10000000-1000-4000-8000-100000000000".replace(/[018]/g, c =>
            (+c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> +c / 4).toString(16)
        );
  }
  getBbEnvTypeSGC(namespace) {
    let bbEnvType;
    let solutionGroupCode;
    if (namespace.indexOf("prod") !== -1 || namespace.indexOf("prodaus") !== -1) 
    {
      bbEnvType = "prod";
      solutionGroupCode = "xti";
    }
    else if (namespace.indexOf("stg4") !== -1) {
      bbEnvType = "qa";
      solutionGroupCode = "xti_stg4";
    }
    else {
      bbEnvType = "qa";
      solutionGroupCode = "xti_qa"
    }
    return {bbEnvType, solutionGroupCode};
  }
  render() {
    const {
      webKey,
      dealerCode,
      dealer,
      scopedDealers,
      user,
      hasNonbaseLocale,
      languages,
      baseLocale,
      locale,
      supportedLocales,
      localeStrings,
      liveCount,
      stagedCount,
      mixedPricing,
      allMakeList,
      makelist,
      vehicleGroupMakeList,
      dealerCatalogs,
      stagingMakeList,
      makeVariantMap,
      makeLaborRatesMap,
      makeRateCodesMap,
      dealerLaborRateCodes,
      rawDealerVariantChanges,
      allMakesPricingMethod,
      metaVehicleDimensionsByMake,
      serviceCategories,
      serviceCategoryValueLabelMap,
      operationlist,
      selectedMenuType,
      isBmwValueServicesEnabled,
      enableDMSPlusContent,
      manualOpcodes,
      cancelOpcodeValidationDate,
      variantFilters,
      tellUsMoreServiceId,
      engageEnabled,
      SPISQSFeatureEnabled,
      inspectionItemMap,
      dealerMakeInspectionItemMap,
      dealerMakeCategoryInspectionItemMap,
      serviceToSkillsMap,
      // categoryGroups,
      // categoryGroupsMap,
      serviceCategoryGroupsMap,
      previewState,
      partManufacturerCodeMap,
      isScopedDealer,
      enableServiceContractV2
    } = this.state;
    const dealerCodeVal = !dealer.dealerCode ? "" : dealer.dealerCode;
    /* set localstate to context here */
    const contextValue = {
      qsParams: window.location.search,
      webKey,
      dealerCode,
      dealer,
      scopedDealers,
      user,
      isStaging: isStagedCatalog(dealerCodeVal),
      isBmwValueServicesEnabled,
      enableDMSPlusContent,
      enableServiceContractV2,
      hasNonbaseLocale,
      languages,
      baseLocale,
      locale,
      supportedLocales,
      localeStrings,
      liveCount,
      stagedCount,
      mixedPricing,
      allMakeList,
      makelist,
      vehicleGroupMakeList,
      dealerCatalogs, // clone of makelist used by Settings page
      stagingMakeList,
      makeVariantMap,
      makeLaborRatesMap,
      makeRateCodesMap,
      dealerLaborRateCodes,
      rawDealerVariantChanges,
      allMakesPricingMethod,
      serviceCategories,
      serviceCategoryValueLabelMap,
      operationlist,
      selectedMenuType,
      updateSelectedMenuType: selectedMenuType => {
        this.setState({ selectedMenuType });
      },
      manualOpcodes,
      cancelOpcodeValidationDate,
      variantFilters,
      testName: this.state.testName,
      tellUsMoreServiceId,
      engageEnabled,
      SPISQSFeatureEnabled,
      inspectionItemMap,
      dealerMakeInspectionItemMap,
      dealerMakeCategoryInspectionItemMap,
      serviceToSkillsMap,
      // categoryGroups,
      // categoryGroupsMap,
      serviceCategoryGroupsMap,
      previewState,
      partManufacturerCodeMap,
      /* Ag-Grid control saved in context */
      demoGridState: this.state.demoGridState,
      operationsGrid: this.state.operationsGrid,
      dashboard: this.state.dashboard,
      opcodeValidationGrid: this.state.opcodeValidationGrid,
      partsPricingGrid: this.state.partsPricingGrid,
      fluidsPricingGrid: this.state.fluidsPricingGrid,
      laborRatesGrid: this.state.laborRatesGrid,
      payTypesGrid: this.state.payTypesGrid,
      discountsGrid: this.state.discountsGrid,
      menuTypesGrid: this.state.menuTypesGrid,
      dealerMenusGrid: this.state.dealerMenusGrid,
      packagesGrid: this.state.packagesGrid,
      reportsGrid: this.state.reportsGrid,
      translationGrid: this.state.translationGrid,
      /* set preview state to App context */
      preview: this.state.preview,
      previewSettings: this.state.previewSettings,
      currentPage: this.state.currentPage,
      isBridgeBarEnabled: this.state.isBridgeBarEnabled,
      reloadPageWithLocale: this.reloadPageWithLocale,
      updateMakeList: this.updateMakeList,
      updateDealerCatalogs: this.updateDealerCatalogs,
      updateOperationList: this.updateOperationList,
      updateInspectionItemMap: this.updateInspectionItemMap,
      updateServiceCategories: this.updateServiceCategories,
      loadServiceCategories: this.loadServiceCategories,
      loadSupportedMakes: this.loadSupportedMakes,
      updateOperationAfterSave: this.updateOperationAfterSave,
      updateDealerLaborRates: this.updateDealerLaborRates,
      /* Ag-grid state handlers */
      setDemoGridState: this.setDemoGridState,
      setOperationGridState: this.setOperationGridState,
      setDashboardState: this.setDashboardState,
      setOpcodeValidationGridState: this.setOpcodeValidationGridState,
      setPartsPricingGridState: this.setPartsPricingGridState,
      setFluidsPricingGridState: this.setFluidsPricingGridState,
      setLaborRatesGridState: this.setLaborRatesGridState,
      setPayTypesGridState: this.setPayTypesGridState,
      setDiscountsGridState: this.setDiscountsGridState,
      setPackagesGridState: this.setPackagesGridState,
      setMenuTypesGridState: this.setMenuTypesGridState,
      setDealerMenusGridState: this.setDealerMenusGridState,
      setReportsGridState: this.setReportsGridState,
      setTranslationGridState: this.setTranslationGridState,
      toggleTestName: this.toggleTestName,
      contentPriceStatuses: this.state.contentPriceStatuses,
      setContentPriceStatuses: this.setContentPriceStatuses,
      discardUnsavedChanges: this.discardUnsavedChanges,
      setManualOpcodes: this.setManualOpcodes,
      setCancelOpcodeValidationDate: this.setCancelOpcodeValidationDate,
      metaVehicleDimensionsByMake,
      intervalsByMake: this.state.intervalsByMake,
      intervalsMapByMake: this.state.intervalsMapByMake,
      dealerMileagesByMake: this.state.dealerMileagesByMake,
      dealerMileagesMapByMake: this.state.dealerMileagesMapByMake,
      selectableDrivingConditions: this.state.selectableDrivingConditions,
      selectableDrivingConditionsMap: this.state.selectableDrivingConditionsMap,
      updateDealerIntervals: this.updateDealerIntervals,
      updateDealerMileages: this.updateDealerMileages,
      updateVariantFilters: this.updateVariantFilters,
      updateServiceToSkillsMap: this.updateServiceToSkillsMap,
      /* setters - update local state preview props */
      updateRawDealerVariantChanges: (
        allMakesPricingMethod,
        rawDealerVariantChanges
      ) => {
        this.setState({ allMakesPricingMethod, rawDealerVariantChanges });
      },
      updateSortedMenuTyeps: sortedMenuTypes => {
        const { preview } = this.state;
        preview.sortedMenuTypes = sortedMenuTypes;
      },
      updatePreviewSearch: search => {
        const { preview } = this.state;
        if (search) {
          preview.search = search;
          preview.isPreview = search.isPreview;
        }
        console.log("updatePreviewSearch", preview, this.state.previewSettings);
      },
      updatePreviewSettings: settings => {
        const { previewSettings } = this.state;
        const {
          search,
          selectors,
          recentlyPreview,
          favorites,
          commonVehicles
        } = settings;
        if (search) {
          previewSettings.search = search;
        }
        if (selectors) {
          previewSettings.selectors = selectors;
        }
        if (recentlyPreview) {
          previewSettings.recentlyPreview = recentlyPreview;
        }
        if (favorites) {
          previewSettings.favorites = favorites;
        }
        if (commonVehicles) {
          previewSettings.commonVehicles = commonVehicles;
        }
        console.log("updatePreviewSettings", previewSettings);
      },
      // this update toggle switch and driving cond. radio
      updatePreviewMode: (isPreview, drivingCondition) => {
        const { preview } = this.state;
        preview.isPreview = isPreview;
        const { search } = preview;
        search.isPreview = isPreview;
        search.drivingCondition = drivingCondition;
        search.defaultDrivingCondition = drivingCondition;
        console.log(
          "updatePreviewMode for isPreview",
          isPreview,
          drivingCondition,
          preview,
          this.state.previewSettings
        );
      },
      updatePreviewDrivingCond: drivingConditions => {
        const { preview } = this.state;
        preview.drivingConditions = drivingConditions;
        console.log(
          "updatePreviewDrivingCond",
          drivingConditions,
          preview,
          this.state.previewSettings
        );
      },
      updateMileagePoints: mileagePoints => {
        const { preview, enableDMSPlusContent } = this.state;
        preview.mileagePoints = mileagePoints;
        // by default, set first mileagePoint here
        if (mileagePoints && mileagePoints.length > 0) {
          const defaultMileage = enableDMSPlusContent
            ? ""
            : mileagePoints[0].value;
          preview.currentMileagePoint = defaultMileage || "";
        }
        this.setState({ preview });
        console.log("updateMileagePoints", mileagePoints);
      },
      updateCurrentMileage: currentMileagePoint => {
        const { preview } = this.state;
        if (preview.currentMileagePoint !== currentMileagePoint) {
          preview.currentMileagePoint = currentMileagePoint;
          console.log("updateMileagePoints", currentMileagePoint);
          this.setState({ preview });
        }
      },
      updatePreviewMenus: (isPreview, response) => {
        const { preview } = this.state;
        preview.isPreview = isPreview;
        preview.menuResults = response;
        console.log("updatePreviewMenus with isPreview", isPreview, response);
      },
      updatePreviewAlacarte: (isPreview, response) => {
        const { preview } = this.state;
        preview.isPreview = isPreview;
        preview.alacarteResults = response;
        console.log("updatePreviewAlacarte for isPreview", isPreview, response);
      },
      vehicleGroupCallbackParams: this.state.vehicleGroupCallbackParams,
      saveServiceCategoryGroupsMap: this.saveServiceCategoryGroupsMap,
      savePreviewState: this.savePreviewState,
      saveCategoryGroupMappings: this.saveCategoryGroupMappings,
      getClassWithBridgeBar: this.getClassWithBridgeBar
    };
    let content = (
      <div style={{ margin: 25 }}>
        <LoadingIndicator htmlId="LoadingMask" size="large" />
      </div>
    );
    const dirtyPopup = (
      <DirtyCheckPopup
        showDirtyModal={this.state.showDirtyModal}
        title={localeStrings["xmm.portal.speed_bump.title"]}
        message={localeStrings["xmm.portal.speed_bump.message"]}
        okText={localeStrings["xmm.portal.speed_bump.discard"]}
        cancelText={localeStrings["xmm.portal.speed_bump.stay_on_page"]}
        cancelAction={this.closeSpeedBump}
        discardAction={this.state.speedBumpCallback}
      />
    );
    const sessionExpiredPopup = (
      <SimpleModalDialog
        show={this.state.showSessionExpired}
        title={"Session Expired"}
        message={
          "Your session has been expired.  Please close it to see the login page."
        }
        actionButtonLabel={"OK"}
        dialogHtmlId={"sessionExpiredId"}
        actionHtmlId={"actionId"}
        titleId={"xmm.portal.session.expired.title"}
        messageId={"xmm.portal.session.expired.message"}
        actionButtonLabelId={"xmm.portal.common.ok_button"}
        doAction={() => {
          redirectToDefaultPage(webKey);
          this.setState({ showSessionExpired: false });
        }}
        doClose={() => {
          redirectToDefaultPage(webKey);
          this.setState({ showSessionExpired: false });
        }}
      />
    );
    const bannerSection = null;
    /* Global banner - add conditions to show/hide banner */
    /*
    const isStaging = contextValue.dealerCode.indexOf("__STG_") !== -1;
    if (isStaging && this.state.genericMsg) {
      bannerSection = (
        <Alert
          htmlId="appBanner"
          className=""
          type={this.state.statusType}
          displayCloseButton={false}
        >
          {this.state.genericMsg}
        </Alert>
      );
    }
    */
    // Render children pages only when dealerCode is ready
    if (this.state.browserSupport) {
      if (
        this.state.authStatus === "success" &&
        this.state.isAuth &&
        isScopedDealer
      ) {
        // Wait until dealercode available for
        if (dealerCatalogs.length === 0) {
          content = (
            <Card htmlId="CardErrorPage">
              <div className="template-content-wrapper content-wrapper__content-only">
                <PageHeader>
                  {this.state.localeStrings["xmm.portal.common.app_name"]}
                </PageHeader>
                <Alert htmlId="systemAlert" type="danger">
                  {
                    this.state.localeStrings[
                      "xmm.portal.common.no_dealer_catalog"
                    ]
                  }
                </Alert>
              </div>
            </Card>
          );
        } else if (!isEmptyString(this.state.dealerCode)) {
          const calculatedPricing = this.hasCalculatedPricing(
            makelist,
            makeVariantMap
          );
          const userAccess = {
            calculatedPricing,
            anyMakeOnly: makelist.length === 0,
            hasNonbaseLocale,
            commonOpsPayTypesEnabled: dealer.commonOpsPayTypesEnabled,
            locale,
            user,
            enableDMSPlusContent
          };
          content = (
            <div className="xtime-support">
              <LanguageProvider
                locale={locale.replace("_", "-")} // "en-US"
                key={locale.replace("_", "-")}
                messages={localeStrings}
              >
                <AppContext.Provider value={contextValue}>
                  <div className="main-layout">
                    <div id="wrapper" className="content">
                      <Header />
                      <Nav links={modules.getModuleMapping(userAccess)} />
                      {<ToastDefault />}

                      <div className="page-wrapper">
                        {bannerSection}
                        {dirtyPopup}
                        {sessionExpiredPopup}
                        {modules.routes.map(route => (
                          <Route
                            key={route.path}
                            path={route.path}
                            exact={route.exact}
                            component={route.main}
                          />
                        ))}
                        <Footer />
                      </div>
                    </div>
                  </div>
                </AppContext.Provider>
              </LanguageProvider>
            </div>
          );
        }
      } else if (this.state.authStatus === "noaccess") {
        content = (
          <Card htmlId="CardErrorPage">
            <div className="template-content-wrapper content-wrapper__content-only">
              <PageHeader>
                {this.state.localeStrings["xmm.portal.common.app_name"]}
              </PageHeader>
              <Alert htmlId="systemAlert" type="danger">
                {this.state.authMsg}
              </Alert>
            </div>
          </Card>
        );
      }
    } else if (this.state.authStatus === "error") {
      content = (
        <Card htmlId="CardErrorPage">
          <div className="template-content-wrapper content-wrapper__content-only">
            <PageHeader>
              {
                this.state.localeStrings[
                  "xmm.portal.common.browser_error_title"
                ]
              }
            </PageHeader>
            <Alert htmlId="systemAlert" type="danger">
              {this.state.authMsg}
            </Alert>
          </div>
        </Card>
      );
    }
    return content;
  }
}


// function refreshSession() {
//   console.log("refreshSession");
//   window.dispatchEvent(
//     new CustomEvent("sessioinExpired", {
//       detail: null,
//       bubbles: true,
//       cancelable: true
//     })
//   );
// }

export default App;
