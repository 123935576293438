/* eslint-disable no-console */
import React, { Component } from "react";
import { AppContext } from "../../../../components/app-context";
import {
  makeSecureRestApi,
  showBodyMask,
  hideBodyMask
} from "../../../../api/xmmAxios";
import { toast } from "@cx/ui/Toast";
import MenuTypesHeader from "./content/MenuTypesHeader";
import MenuTypeCard from "./content/MenuTypeCard";
import MenuTypeForm from "./content/MenuTypeForm";
import Confirmation from "../../../../commonUtil/dialog/Confirmation";
import { menuTypeComparator } from "../../../../commonUtil/utils/list";
import GenericSlider from "../../../../commonUtil/components/GenericSlider";
import { doesEmpty } from "../../../../commonUtil/utils/object";
import { xlate } from "../../../../commonUtil/i18n/locales";
import * as gtmEvents from "../../../utils/gtag-eventlist";
export default class MenuTypesPage extends Component {
  static contextType = AppContext;
  constructor(props, context) {
    super(props, context);
    this.headerRef = React.createRef();
    this.menuTypeFormRef = React.createRef();
    this.handleFilterMenuTypesByMakeAndShowDisabled =
      this.handleFilterMenuTypesByMakeAndShowDisabled.bind(this);
    this.handleDeleteMenuType = this.handleDeleteMenuType.bind(this);
    this.deleteFunction = this.deleteFunction.bind(this);
    this.handleCopyMenuType = this.handleCopyMenuType.bind(this);
    this.copyMenuType = this.copyMenuType.bind(this);
    this.handleUpdateMenuTypeAfterSave =
      this.handleUpdateMenuTypeAfterSave.bind(this);
    this.initializeLocaleValues();
    this.state = {
      menuTypes: [],
      menuTypeMap: {},
      parentMenuTypeMap: {},
      filteredMenuTypes: [],
      showDeleteConfirmation: false,
      sliderWidth: 850,
      flexWidth: false,
      sliderMenuType: {},
      showSlider: false
    };
  }

  /**
   * Add event listeners
   * when an instance of a component is being created and inserted into the DOM
   */
  componentDidMount() {
    this.loadMenuTypes();

    window.addEventListener(
      "filterMenuTypesByMakeAndShowDisabled",
      this.handleFilterMenuTypesByMakeAndShowDisabled,
      false
    );
    window.addEventListener("deleteMenuType", this.handleDeleteMenuType, false);
    window.addEventListener("copyMenuType", this.handleCopyMenuType, false);
    window.addEventListener("showMenuTypeSlider", this.handleShowSlider, false);
    window.addEventListener(
      "updateMenuTypeAfterSave",
      this.handleUpdateMenuTypeAfterSave,
      false
    );
  }

  /**
   * Remove event listeners
   * when a component is being removed from the DOM
   */
  componentWillUnmount() {
    window.removeEventListener(
      "filterMenuTypesByMakeAndShowDisabled",
      this.handleFilterMenuTypesByMakeAndShowDisabled,
      false
    );
    window.removeEventListener(
      "deleteMenuType",
      this.handleDeleteMenuType,
      false
    );
    window.removeEventListener("copyMenuType", this.handleCopyMenuType, false);
    window.removeEventListener(
      "showMenuTypeSlider",
      this.handleShowSlider,
      false
    );
    window.removeEventListener(
      "updateMenuTypeAfterSave",
      this.handleUpdateMenuTypeAfterSave,
      false
    );
  }
  initializeLocaleValues() {
    this.menuTypesLabel = xlate("xmm.portal.nav.menu_types");
    this.copyConfirmationMsg = xlate("xmm.portal.menutype.copy_confirmation");
    this.deleteConfirmationMsg = xlate(
      "xmm.portal.menutype.delete_confirmation"
    );
    this.addMenuLabel = xlate("xmm.portal.menutype.add_menu");
    this.deletedMsg = xlate("xmm.portal.common.menu_type_deleted");
    this.savingMsg = xlate("xmm.portal.common.saving");
    this.savedMsg = xlate("xmm.portal.common.saved");
    this.saveErrorMsg = xlate("xmm.portal.errors.save_data_error");
    this.deleteErrorMsg = xlate("xmm.portal.menutype.error.delete_menu_type");
    this.copiedMenuTypeMsg = xlate("xmm.portal.msg.copied_menu_type");
  }
  loadMenuTypes = () => {
    const { dealerCode, makelist, locale } = this.context;
    const makesMap = {};
    makelist.forEach(m => {
      makesMap[m.make] = true;
    });
    showBodyMask();
    makeSecureRestApi(
      {
        url: "/ops/proxyapi/ddsproxy/rest/proc/getEnhancedMenuTypes",
        method: "get",
        data: {},
        params: { dealerCode, locale }
      },
      dataList => {
        // console.log(dataList);

        const menuTypes = dataList
          .filter(menuType => {
            return menuType.description;
            // return makesMap[menuType.make] === true;
          })
          .sort(menuTypeComparator);
        const menuTypeMap = {};
        const parentMenuTypeMap = {};
        menuTypes.forEach(menuType => {
          const { includesId } = menuType;
          if (!doesEmpty(includesId)) {
            parentMenuTypeMap[includesId.toString()] = true;
          }
          menuTypeMap[menuType.menuTypeId.toString()] = menuType;
        });
        const { make, showDisabled } = this.headerRef.current
          ? this.headerRef.current.state
          : { make: "all", showDisabled: true };
        this.setState({ menuTypes, menuTypeMap, parentMenuTypeMap }, () => {
          this.updateState(make, showDisabled);
        });
        // this.setState({ menuTypes, filteredMenuTypes: [...menuTypes] });
        hideBodyMask();
      },
      error => {
        hideBodyMask();
        toast.error(error.message);
      }
    );
  };

  getMenuCardKey(menuType, index) {
    const { make, menuTypeId } = menuType;
    return `${make}_${menuTypeId}_${index}`;
  }

  updateState(make, showDisabled) {
    const currentMenuTypes = this.state.menuTypes;
    // sort the menu types
    const menuTypes = currentMenuTypes.sort(this.menuTypeComparator);
    const filteredMenuTypes = menuTypes.filter(menuType => {
      return (
        (make === "all" || menuType.make === make) &&
        (showDisabled || menuType.enabled === 1)
      );
    });
    this.setState({ menuTypes, filteredMenuTypes });
  }

  handleFilterMenuTypesByMakeAndShowDisabled = event => {
    const { showDisabled, make, dealerMenuType } = event.detail;
    const { menuTypes } = this.state;
    if (dealerMenuType) {
      const index = menuTypes.findIndex(menuType => {
        return menuType.menuTypeId === dealerMenuType.menuTypeId;
      });
      if (index !== -1) {
        menuTypes[index] = dealerMenuType;
      }
      this.updateState(make, showDisabled);
    } else {
      this.updateState(make, showDisabled);
    }
  };

  handleCopyMenuType = event => {
    this.setState({
      showCopyMenuConfirmation: true,
      actionDetail: event.detail
    });
  };

  copyMenuType = () => {
    const { updateStatusBox } = this.headerRef.current;
    updateStatusBox(this.savingMsg, "pending", false);
    const { menuType } = this.state.actionDetail;
    const { menuTypeId } = menuType;
    const data = { requestType: "", authId: "", menuTypeId };
    const restEndPoint = "ops/proxyapi/ddsproxy/rest/proc/copyDealerMenuType";
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "post",
        data,
        params: {}
      },
      () => {
        this.loadMenuTypes();
        updateStatusBox(this.savedMsg, "success", true);
      },
      error => {
        const msg = error["message"] ? error.message : this.saveErrorMsg;
        updateStatusBox(msg, "error", false, true);
      }
    );
    this.closeCopyMenuModal();
  };

  closeCopyMenuModal = () => {
    this.setState({ showCopyMenuConfirmation: false });
  };

  closeDeleteModal = () => {
    this.setState({ showDeleteConfirmation: false });
  };

  deleteFunction = () => {
    // console.log("........deleteFunction........");
    const { make, showDisabled, menuType } = this.state.actionDetail;
    this.deleteMenuType(make, showDisabled, menuType);
    this.closeDeleteModal();
  };

  handleDeleteMenuType = event => {
    this.setState({ showDeleteConfirmation: true, actionDetail: event.detail });
  };

  deleteMenuType(make, showDisabled, menuType) {
    const { updateStatusBox } = this.headerRef.current;
    updateStatusBox(this.savingMsg, "pending", false);
    const { menuTypeId } = menuType;
    const restEndPoint = "ops/proxyapi/ddsproxy/rest/proc/deleteDealerMenuType";
    makeSecureRestApi(
      {
        url: restEndPoint,
        method: "get",
        data: {},
        params: { menuTypeId }
      },
      data => {
        // NOTE: we get blank response on success; call this service to get single catalog record
        // ag-grid API to update rowData for edited cell.
        if (Array.isArray(data) && data.length !== 0 && data[0].isError) {
          updateStatusBox(this.deleteErrorMsg, "error", false, true);
          return;
        }
        const { menuTypes } = this.state;
        const index = menuTypes.findIndex(m => {
          return m.menuTypeId === menuType.menuTypeId;
        });
        if (index !== -1) {
          menuTypes.splice(index, 1);
        }
        this.updateState(make, showDisabled);
        updateStatusBox(this.savedMsg, "success", true);
      },
      error => {
        const msg = error["message"] ? error.message : this.saveErrorMsg;
        updateStatusBox(msg, "error", false, true);
      }
    );
  }

  handleShowSlider = event => {
    const { showSlider, sliderMenuType } = event.detail;
    gtmEvents.trackGtagPageview("/menus/menutypes/manage-menutype");
    this.setState({ showSlider, sliderMenuType });
  };

  closeSlider = () => {
    const callback = () => {
      this.setState({ showSlider: false });
    };
    const isDirtyFunc = () => {
      if (this.menuTypeFormRef && this.menuTypeFormRef.current) {
        return this.menuTypeFormRef.current.state.dirty;
      }
    };
    if (this.context.discardUnsavedChanges({}, callback, isDirtyFunc)) {
      this.setState({ showSlider: false });
    }
  };

  getSliderTitle(sliderMenuType) {
    if (sliderMenuType) {
      if (sliderMenuType.description && sliderMenuType.description !== "") {
        return <span>{sliderMenuType.description}</span>;
      }
      return <span>{this.addMenuLabel}</span>;
    }
    return <span />;
  }

  handleUpdateMenuTypeAfterSave = event => {
    const { menuTypes, menuTypeMap } = this.state;
    const { addMode, menuType } = event.detail;
    const { make, showDisabled } = this.headerRef.current.state;
    if (addMode) {
      menuTypes.splice(0, 0, menuType);
      menuTypeMap[menuType.menuTypeId.toString()] = menuType;
      this.setState({ menuTypeMap });
      this.updateState(make, showDisabled);
    } else {
      const detail = { make, showDisabled, dealerMenuType: menuType };
      const event = { detail };
      this.handleFilterMenuTypesByMakeAndShowDisabled(event);
    }
  };
  render() {
    const { makelist,getClassWithBridgeBar } = this.context;
    const {
      menuTypes,
      menuTypeMap,
      parentMenuTypeMap,
      sliderMenuType,
      filteredMenuTypes,
      showDeleteConfirmation,
      showCopyMenuConfirmation
    } = this.state;
    const menuTypeHeader = (
      <MenuTypesHeader ref={this.headerRef} makes={makelist} />
    );
    const menuTypesCards = filteredMenuTypes.map((menuType, index) => {
      const key = this.getMenuCardKey(menuType, index);
      return (
        <MenuTypeCard
          key={key}
          menuType={menuType}
          menuTypeMap={menuTypeMap}
          parentMenuTypeMap={parentMenuTypeMap}
          makes={makelist}
          headerRef={this.headerRef.current}
          index={index}
        />
      );
    });
    const deleteModal = (
      <Confirmation
        htmlId="deleteMenuType"
        message={this.deleteConfirmationMsg}
        proceedButtonStyle="danger"
        show={showDeleteConfirmation}
        actionFunction={this.deleteFunction}
        closeDialog={this.closeDeleteModal}
      />
    );
    const copyMenuConfirmation = (
      <Confirmation
        htmlId="copyMenuType"
        titleId="xmm.portal.menutype.copy_confirmation.title"
        message={this.copyConfirmationMsg}
        proceedButtonStyle="primary"
        show={showCopyMenuConfirmation}
        actionFunction={this.copyMenuType}
        closeDialog={this.closeCopyMenuModal}
      />
    );
    const sliderTitle = this.getSliderTitle(sliderMenuType);
    const menuTypeFormSlider = sliderMenuType ? (
      <GenericSlider
        title={sliderTitle}
        htmlId="menuTypeSlider"
        showSlide={this.state.showSlider}
        toggleSlider={this.closeSlider}
        sliderWidth={this.state.sliderWidth}
        flexWidth={this.state.flexWidth}
        className={getClassWithBridgeBar("xmm-slider-1")}
      >
        <div>
          <MenuTypeForm
            htmlId="menuTypeForm"
            ref={this.menuTypeFormRef}
            makes={makelist}
            menuTypes={menuTypes}
            menuType={sliderMenuType || {}}
            closeSlider={this.closeSlider}
          />
        </div>
      </GenericSlider>
    ) : (
      ""
    );
    return (
      <div className="xmm-center-container">
        <div>
          <h3>{this.menuTypesLabel}</h3>
        </div>
        {menuTypeFormSlider}
        {deleteModal}
        {copyMenuConfirmation}
        <form id="menuTypesForm" autoComplete="off">
          <div>{menuTypeHeader}</div>
          <div className={getClassWithBridgeBar("xmm-card-container")}>{menuTypesCards}</div>
        </form>
      </div>
    );
  }
}
