/* eslint-disable no-console */
import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import { AppContext } from "../../../../components/app-context";
import CustomLoadingOverlay from "../../../../commonUtil/components/loadingmask/CustomLoadingOverlay";
import Button from "@cx/ui/Button";
import IconDelete from "@cx/ui/Icons/IconDelete";
import GenericSlider from "../../../../commonUtil/components/GenericSlider";
import { doesEmpty } from "../../../../commonUtil/utils/object";
import { applyCustomKeyNavigation } from "../../../../commonUtil/utils/keyNavigation";
import { toEmptyStringIfUndefined } from "../../../../commonUtil/utils/string";
import {
  blankValueFormatter,
  extractLaborRates,
  extractLaborRateValues
} from "../../../../commonUtil/utils/formatter";
import TextEditor from "../../../../commonUtil/editors/TextEditor";
import {
  makeSecureRestApi,
  showBodyMask,
  hideBodyMask
} from "../../../../api/xmmAxios";
import {
  PayTypeRecord,
  // PayTypeGroup,
  DealerTrackDefaultPayTypes
} from "../../../../constants/ModuleConstants";
import AddPayTypeFormPage from "./content/AddPayTypeFormPage";
import { toast } from "@cx/ui/Toast";
import * as gtmEvents from "../../../utils/gtag-eventlist";
import Confirmation from "../../../../commonUtil/dialog/Confirmation";
import { loadAgGridLocale } from "../../../../i18n/LocaleSender";
import { xlate } from "../../../../commonUtil/i18n/locales";
import { sortByMake } from "../../../../commonUtil/utils/filter";
import StatusBox from "../../../../commonUtil/components/templates/StatusBox";
import { getCellClassRule } from "../../../../commonUtil/utils/validation";
import * as formvalidator from "../../../formik/formvalidator";
import CheckboxCell from "../../../../commonUtil/renders/CheckboxCell";

class PayTypesPage extends Component {
  static contextType = AppContext;

  constructor(props, context) {
    super(props, context);

    this.payTypeFormRef = React.createRef();

    // Bind grid functions in constructor
    this.getRowNodeId = this.getRowNodeId.bind(this);
    this.onCellClickedEvent = this.onCellClickedEvent.bind(this);
    this.onCellValueChanged = this.onCellValueChanged.bind(this);
    this.handleColumnResized = this.handleColumnResized.bind(this);
    this.onSearchBoxChanged = this.onSearchBoxChanged.bind(this);
    // bind other external actions
    this.addActionSlider = this.addActionSlider.bind(this);
    this.hideSlider = this.hideSlider.bind(this);
    this.closeDeleteModal = this.closeDeleteModal.bind(this);
    this.confirmDeletePayType = this.confirmDeletePayType.bind(this);
    // this.deletePayType = this.deletePayType.bind(this);
    this.renderMoreButton = this.renderMoreButton.bind(this);
    this.updatePayTypeAfterSave = this.updatePayTypeAfterSave.bind(this);
    this.updateStatusBox = this.updateStatusBox.bind(this);
    this.onFirstDataRendered = this.onFirstDataRendered.bind(this);
    this.handleSaveCellEdit = this.handleSaveCellEdit.bind(this);
    this.handleDeletePayTypes = this.handleDeletePayTypes.bind(this);
    this.handleGridSizeChanged = this.handleGridSizeChanged.bind(this);
    this.addDefaultPayTypes = this.addDefaultPayTypes.bind(this);
    this.getColumnList = this.getColumnList.bind(this);
    this.laborRateCellEditorParams = this.laborRateCellEditorParams.bind(this);

    const { dealer, localeStrings, enableDMSPlusContent,SPISQSFeatureEnabled } = context;

    const { commonOpsLaborGridsEnabled, dmsType } = dealer;
    const isDealerTrack = dmsType === "DEALERTRACK" || enableDMSPlusContent;

    this.initializeLocaleValues(SPISQSFeatureEnabled);
    const validationFields = ["description", "payCode"];
    const editableFields = ["description", "payCode"];
    const checkboxFields = ["defaultPayType"];
    this.groupList = [];
    const gridOptions = {
      // other state props
      commonOpsLaborGridsEnabled,
      searchKey: context.payTypesGrid.searchKey,
      payTypesGrid: context.payTypesGrid,
      pageTitle: this.pageTitle,
      editOption: null, // set values as edit, add, delete
      operationName: "",
      rowRecord: PayTypeRecord,
      sliderWidth: 500,
      flexWidth: false,
      showSlide: false,
      isDealerTrack,
      // ag-grid props
      payTypes: null, // should be null - fix to skip "No records found" msg on grid load.
      selectionlist: [],
      columnDefs: this.getColumnList(localeStrings, commonOpsLaborGridsEnabled),
      defaultColDef: {
        floatingFilter: false, // true - enable column header filters
        sortable: true,
        resizable: true,
        editable: false, // default disable editor
        enableRowGroup: false,
        sortingOrder: ["asc", "desc", null],
        width: 120,
        autoHeight: true,
        filter: "agTextColumnFilter",
        cellClassRules: getCellClassRule(
          validationFields,
          editableFields,
          checkboxFields
        ),
        getQuickFilterText: params => {
          if (!params.column.visible) {
            return null;
          } else {
            return params.value;
          }
        },
        headerComponentParams: {
          template: `
          <div class="ag-cell-label-container" role="presentation">
            <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>
            <div ref="eLabel" class="ag-header-cell-label" role="presentation">
              <span ref="eText" class="ag-header-cell-text" role="columnheader"></span>
              <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>
              <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon" ></span>
              <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon" ></span>
              <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon" ></span>
            </div>
          </div>
          `
        },
        suppressKeyboardEvent: applyCustomKeyNavigation,
        rowGroup: false
      },
      multiSortKey: "ctrl",
      components: {},
      // frameworkComponents: {
      //   payTypeCodeEditor: PayTypeCodeEditor,
      //   customLoadingOverlay: CustomLoadingOverlay,
      //   customNoRowsOverlay: CustomLoadingOverlay,
      //   textEditor: TextEditor
      // },
      loadingOverlayComponent: CustomLoadingOverlay,
      loadingOverlayComponentParams: {
        loadingMessage: "Loading",
        isLoading: true,
        noRows: false
      },
      noRowsOverlayComponent: CustomLoadingOverlay,
      noRowsOverlayComponentParams: {
        loadingMessage: "No records found.",
        isLoading: false,
        noRows: true
      },
      statusBar: {
        statusPanels: [
          {
            statusPanel: "agTotalAndFilteredRowCountComponent",
            align: "left"
          },
          {
            statusPanel: "agFilteredRowCountComponent"
          },
          {
            statusPanel: "agSelectedRowCountComponent",
            align: "left"
          }
        ]
      },
      // true - use browser default tooltip instead of ag-grid tooltip
      enableBrowserTooltips: true,
      columnTypes: {
        numberColumn: {
          maxWidth: 130,
          minWidth: 130,
          filter: "agNumberColumnFilter",
          filterParams: {
            includeBlanksInEquals: false,
            includeBlanksInLessThan: false,
            includeBlanksInGreaterThan: false,
            buttons: ["clear"]
          }
        },
        nonEditableColumn: { editable: false },
        noFilterColumn: {
          width: 100,
          columnGroupShow: "open",
          filter: false
        }
      },
      rowSelection: "multiple",
      isRowSelectable() {
        return true; // to see checkbox
        // return rowNode.data ? rowNode.data.make !== "ANY" : false;
      },
      localeText: loadAgGridLocale(localeStrings),
      sideBar: {
        toolPanels: [
          {
            id: "columns",
            labelDefault: "Columns",
            labelKey: "columns",
            iconKey: "columns",
            toolPanel: "agColumnsToolPanel",
            toolPanelParams: {
              suppressPivots: true,
              suppressPivotMode: true,
              suppressValues: true,
              suppressRowGroups: true
            }
          }
        ],
        hiddenByDefault: false,
        showConfirmAddDefaultPayTypes: false
      }
    };
    this.state = gridOptions;
  }
  /**
   * Add event listeners
   * when an instance of a component is being created and inserted into the DOM
   */
  componentDidMount() {
    window.addEventListener(
      "saveCellEditEvent",
      this.handleSaveCellEdit,
      false
    );
  }
  /**
   * Remove event listeners
   * when a component is being removed from the DOM
   */
  componentWillUnmount() {
    this.saveGridState();
    window.removeEventListener(
      "saveCellEditEvent",
      this.handleSaveCellEdit,
      false
    );
  }
  initializeLocaleValues(SPISQSFeatureEnabled) {

    //console.log('SPISQSFeatureEnabled test :', SPISQSFeatureEnabled); // Add logging
    // console.log('localeStrings:', this.context.localeStrings); 


    this.option = xlate("xmm.portal.pay_types.option");
    this.searchLabel = xlate("xmm.portal.common.search_label");
    this.addPayTypeLabel =SPISQSFeatureEnabled ? xlate("xmm.portal.pay_types.add.cdk") : xlate("xmm.portal.pay_types.add");
    this.pageTitle = SPISQSFeatureEnabled ? xlate("xmm.portal.nav.pay_types.cdk") : xlate("xmm.portal.nav.pay_types");
    this.deletingMsg = xlate("xmm.portal.common.deleting");
    this.deletedMsg = xlate("xmm.portal.common.deleted");
    this.deletingErrorMsg = xlate("xmm.portal.errors.deleting");
    this.savingMsg = xlate("xmm.portal.common.saving");
    this.savedMsg = xlate("xmm.portal.common.saved");
    this.saveErrorMsg = xlate("xmm.portal.common.cannot_save_changes");
    this.addDefaultPayTypesLabel = xlate("xmm.portal.default_pay_types.add");
    this.addCDKPayTypesLabel = xlate("xmm.portal.cdk_pay_types.add");
    this.createDefaultPayTypesInfo = xlate(
      "xmm.portal.default_pay_types.confirm_add"
    );
    this.createCDKPayTypesInfo = xlate("xmm.portal.cdk_pay_types.confirm_add");
  }
  /* Action to save ag-grid {column, filter, pivot, sort} to local state
   */
  saveGridState() {
    const { searchKey } = this.state;
    if (this.gridApi && this.gridColumnApi) {
      const payTypesGrid = {
        colState: this.gridColumnApi.getColumnState(),
        pivotState: this.gridColumnApi.isPivotMode(),
        filterState: this.gridApi.getFilterModel(),
        searchKey
      };
      this.setState({
        payTypesGrid
      });
      this.context.setPayTypesGridState(payTypesGrid);
    }
  }
  /* This Util called to restore ag-grid controls,filters,sorters from app-context when re-visited page */
  restoreGridState() {
    const { colState, filterState, pivotState, searchKey } =
      this.state.payTypesGrid;
    if (colState && this.gridApi && this.gridColumnApi) {
      this.gridColumnApi.applyColumnState(colState);
      this.gridColumnApi.setPivotMode(pivotState);
      // this.gridApi.setSortModel(sortState);
      this.assignColumnState(colState);
      this.gridApi.setFilterModel(filterState);
      this.setState(
        {
          searchKey
        },
        () => {
          this.gridApi.setQuickFilter(searchKey);
        }
      );
    }
  }
  onFirstDataRendered() {
    this.restoreGridState();
    this.sizeToFit();
  }
  handleColumnResized = () => {
    // this.gridApi.resetRowHeights();
  };
  /* IMP - this function required for CRUD operations, to get RowNode */
  getRowNodeId(data) {
    return data.dealerPayTypeId; // primary or unique key of record
  }
  onGridReady = params => {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.loadGridData();
    this.gridApi.closeToolPanel();
    this.applySortConfig();
  };
  sizeToFit() {
    this.gridApi && this.gridApi.sizeColumnsToFit();
  }
  handleGridSizeChanged = event => {
    const { clientWidth, clientHeight } = event;
    if (clientWidth && clientHeight) {
      this.sizeToFit();
    }
  };
  updateState(data) {
    if (data) {
      const datalist = Array.isArray(data) ? data : [];
      if (datalist.length === 0) {
        this.gridApi && this.gridApi.showNoRowsOverlay();
      } else {
        this.gridApi && this.gridApi.hideOverlay();
      }
      this.setState(
        {
          payTypes: datalist
        },
        () => {
          this.sizeToFit();
        }
      );
    }
  }
  loadGridData() {
    const { dealerCode } = this.context;
    const restUrl = `ops/proxyapi/oeproxy/rest/internal/xmmdealer/getDealerPayTypesByDealerType/${dealerCode}`;
    showBodyMask();
    makeSecureRestApi(
      {
        url: restUrl,
        method: "get"
      },
      data => {
        if (data && data.data) {
          // Add Default filter to show only Calculated pricing workflow supported records.
          hideBodyMask();
          this.gridApi && this.gridApi.hideOverlay();
          this.updateState(data.data);
        }
      },
      error => {
        const msg = error["message"]
          ? error.message
          : "There was an error while fetching data for this dealer.  Please try again later.";
        toast.error(msg, {
          closeOnClick: true
        });
        this.gridApi && this.gridApi.showNoRowsOverlay();
        hideBodyMask();
      }
    );
  }

  applySortConfig() {
    const defaultSortModel = [
      {
        colId: "make",
        sortIndex: 0,
        sort: "asc"
      },
      {
        colId: "name",
        sortIndex: 1,
        sort: "asc"
      }
    ];
    // this.gridApi && this.gridApi.setSortModel(defaultSortModel);
    this.assignColumnState(defaultSortModel);
  }
  assignColumnState = defaultSortModel => {
    this.gridColumnApi &&
      this.gridColumnApi.applyColumnState({
        state: defaultSortModel,
        defaultState: {
          // important to say 'null' as undefined means 'do nothing'
          sort: null
        }
      });
  };
  callRefreshAfterMillis(params, gridApi) {
    setTimeout(function () {
      gridApi.refreshCells(params);
    }, 300);
  }
  /* "cellClicked" event handler fired on specific columns */
  onCellClickedEvent() {
    // do nothing for now
  }
  // This event fired after a cell has been changed with default editing
  onCellValueChanged(params) {
    if (
      toEmptyStringIfUndefined(params.oldValue) !==
      toEmptyStringIfUndefined(params.newValue)
    ) {
      if (this.validateField(params)) {
        this.clearFieldValidation(params);
        this.onSaveCellEdit(params);
      }
    }
  }
  deselectDefaultPayType(payType, field) {
    const { payTypes } = this.state;
    const checkedPayTypes = payTypes.filter(
      p =>
        payType.dealerPayTypeId !== p.dealerPayTypeId &&
        p[field] &&
        payType.make === p.make &&
        payType.payTypeGroup === p.payTypeGroup
    );
    if (checkedPayTypes.length !== 0) {
      checkedPayTypes.forEach(p => {
        //
        p[field] = false;
        this.gridApi.applyTransaction({
          update: [p]
        });
        this.savePayload({ data: p, colDef: { field } }, 0, true);
      });
      // const params = {
      //   // don't do force since cell would be flashed as well
      //   force: true,
      //   columns: [field]
      // };
      // this.gridApi.refreshCells(params);
    }
  }
  handleSaveCellEdit = event => {
    event.preventDefault();
    const { data, field, value } = event.detail;
    if (value) {
      this.deselectDefaultPayType(data, field);
    }
    this.savePayload({ data, colDef: { field } }, value);
  };
  onSaveCellEdit(params) {
    this.savePayload(params, params.value);
  }
  /* celledit handler to save edits */
  savePayload(params, value, suppressStatus) {
    if (!suppressStatus) this.updateStatusBox(this.savingMsg, "pending", false);
    const { user } = this.context;
    const { userName } = user;
    const { data, colDef } = params;
    const { field } = colDef;
    const url = `/ops/proxyapi/ddsproxy/rest/table/dealerPayType/${data.dealerPayTypeId}?_method=put`;
    // const url = "/ops/rest-db/updateTableData/paytypes";
    const payload = {
      // dealerPayTypeId: data.dealerPayTypeId,
      [field]: value,
      modUser: userName
    };
    makeSecureRestApi(
      {
        url,
        method: "post",
        data: payload
      },
      () => {
        if (!suppressStatus)
          this.updateStatusBox(this.savedMsg, "success", true);
        this.refreshCell(data, field);
      },
      error => {
        const errMsg = error["message"]
          ? error.message
          : xlate("xmm.portal.errors.save_data_error");
        if (!suppressStatus) this.updateStatusBox(errMsg, "error", false, true);
      }
    );
  }
  confirmDeletePayType = dealerPayType => {
    this.setState({ dealerPayType, showDeleteConfirmation: true });
  };
  handleDeletePayTypes(payType) {
    this.updateStatusBox(this.deletingMsg, "pending", false);
    const { selectionlist } = this.state;
    let deleteCount = 0;
    const url = "/ops/proxyapi/ddsproxy/rest/proc/deletePayType";
    // const restEndPoint = "ops/rest-db/deleteTableRow/paytypes";
    deleteCount++;
    makeSecureRestApi(
      {
        url,
        method: "post",
        data: { dealerPayTypeId: payType.dealerPayTypeId }
      },
      () => {
        const res = this.gridApi.applyTransaction({
          remove: [payType]
        });
        res.remove.forEach(function (rowNode) {
          console.log("Removed Row Node", rowNode);
        });
        this.updateStatusBox(this.deletedMsg, "success", true);
        // this.context.updateDealerLaborRates("delete", payType);
        // GTM - push click event to dataLayer
        // gtmEvents.gtmTrackEvent("xmm.laborrates.delete_rate_click");
        this.closeDeleteModal(deleteCount >= selectionlist.length);
      },
      error => {
        this.closeDeleteModal(deleteCount >= selectionlist.length);
        const msg = error["message"] ? error.message : this.deletingErrorMsg;
        this.updateStatusBox(msg, "error", false, true);
      }
    );
    gtmEvents.gtmTrackEvent("xmm.parts&labor.paytypes.delete_pay_type");
  }
  // Quick filter handler
  onSearchBoxChanged = event => {
    if (event) {
      event.preventDefault();
    }
    if (this.gridApi) {
      const searchKey = document.querySelector("#paytype-search-box").value;
      this.gridApi.setQuickFilter(searchKey);
      this.setState({
        searchKey
      });
    }
  };
  getColumnList(localeStrings) {
    const { dealerLaborRateCodes,SPISQSFeatureEnabled } = this.context;
    const baseCols = [
      {
        headerName: localeStrings["xmm.portal.pay_types.make"],
        headerClass: "ag-text-header",
        cellClass: "xmm-wrap-cell",
        field: "make",
        sortingOrder: ["asc", "desc"],
        maxWidth: 150,
        minWidth: 100,
        editable: false,
        valueFormatter(params) {
          if (params.value.trim().toLowerCase() === "all makes") {
            return "ANY";
          }
          return params.value;
        },
        filter: "agSetColumnFilter",
        suppressSizeToFit: true,
        filterParams: {
          buttons: ["clear"],
          comparator: sortByMake,
          valueFormatter: params => {
            if (params.value.trim().toLowerCase() === "all makes") {
              return "ANY";
            }
            return params.value;
          }
        }
      },
      {
        headerName: SPISQSFeatureEnabled ? localeStrings["xmm.portal.pay_types.pay_code.cdk"] : localeStrings["xmm.portal.pay_types.pay_code"],
        field: "payCode",
        // eslint-disable-next-line no-constant-condition
        editable: true,
        cellEditor: TextEditor,
        cellEditorParams: {
          maxLength: 50,
          cellHeight: 25
        },
        cellClass: "editable-cell",
        tooltipField: "Pay Code",
        tooltipComponentParams: { field: "payCode" },
        minWidth: 120,
        maxWidth: 200,
        sortingOrder: ["asc", "desc"],
        valueFormatter: blankValueFormatter,
        filter: "agSetColumnFilter",
        filterParams: {
          buttons: ["clear"]
        }
      },
      {
        headerName: localeStrings["xmm.portal.pay_types.description"],
        field: "description",
        editable(params) {
          const { newValue } = params;
          return (
            doesEmpty(newValue) ||
            formvalidator.validateAlphaNumPunctuation(newValue)
          );
        },
        cellEditor: TextEditor,
        cellEditorParams: { keepInvalidValue: true, maxLength: 50 },
        cellClass: "editable-cell",
        tooltipField: "description",
        tooltipComponentParams: { field: "description" },
        enableRowGroup: false,
        valueFormatter: blankValueFormatter,
        minWidth: 150,
        filterParams: {
          buttons: ["clear"]
        }
      },
      {
        headerName: SPISQSFeatureEnabled ? localeStrings["xmm.portal.pay_types.pay_type_group.cdk"] : localeStrings["xmm.portal.pay_types.pay_type_group"],
        headerClass: "ag-text-header",
        field: "payTypeGroup",
        editable: false,
        cellClass: "xmm-wrap-cell",
        // cellClass: "editable-caret-cell",
        // cellEditor: "agRichSelectCellEditor",
        // cellEditorParams: {
        //   values: ["Customer Pay", "Warranty", "Internal", "Service Catalog"],
        //   cellHeight: 25
        // },
        maxWidth: SPISQSFeatureEnabled ? 140 : 125,
        minWidth: SPISQSFeatureEnabled ? 140 : 125,
        filter: "agSetColumnFilter",
        filterParams: {
          buttons: ["clear"]
        }
      },
      {
        headerName: localeStrings["xmm.portal.grid.labor_rate"],
        headerClass: "ag-text-header",
        field: "dealerLaborRateCodeId",
        editable: true,
        cellEditorPopup: true,
        // hide: !commonOpsLaborGridsEnabled,
        cellClass: "editable-caret-cell",
        cellEditorSelector: this.laborRateCellEditorParams,
        refData: extractLaborRates(dealerLaborRateCodes),
        minWidth: 125,
        filter: "agSetColumnFilter",
        filterParams: {
          buttons: ["clear"]
        }
      },
      {
        headerName: localeStrings["xmm.portal.pay_types.default"],
        field: "defaultPayType",
        cellRenderer: CheckboxCell,
        cellRendererParams: {
          field: "defaultPayType"
        },
        filter: "agSetColumnFilter",
        filterParams: { suppressMiniFilter: false, buttons: ["clear"] },
        editable: false,
        enableRowGroup: false,
        suppressSizeToFit: true,
        minWidth: 80,
        maxWidth: 80
      },
      {
        headerName: "",
        field: "",
        pinned: "right",
        /* editable: true, */
        cellClass: "editable-cell",
        /* cellEditorSelector() {
          return { component: PayTypeCodeEditor };
        },*/
        cellEditorParams: {
          parentHandle: this
        },
        cellRenderer: this.renderMoreButton,
        enableRowGroup: false,
        maxWidth: 61,
        minWidth: 61,
        width: 61,
        filter: false,
        suppressSizeToFit: true,
        suppressMenu: true,
        suppressColumnsToolPanel: true
      }
    ];
    return baseCols;
  }
  laborRateCellEditorParams(params) {
    const { makeRateCodesMap } = this.context;
    const make = params.data.make === "ALL MAKES" ? "ANY" : params.data.make;
    return {
      component: "agRichSelectCellEditor",
      params: {
        values: extractLaborRateValues(makeRateCodesMap, make)
      }
    };
  }
  setFieldValidation(operation, field, errorKey) {
    const { localeStrings } = this.context;
    if (!operation.errors) {
      operation.errors = {};
    }
    operation.errors[field] = localeStrings[errorKey];
    this.refreshCell(operation, field);
    this.updateStatusBox(operation.errors[field], "error", false, true);
  }
  refreshCell(record, field) {
    const rowNode =
      this.gridApi && this.gridApi.getRowNode(record.dealerPayTypeId);
    if (rowNode) {
      const params = {
        // don't do force since cell would be flashed as well
        // force: true,
        columns: [field],
        rowNodes: [rowNode]
      };
      this.gridApi.refreshCells(params);
    }
  }
  clearFieldValidation(params) {
    const { field } = params.colDef;
    const operation = params.data;
    if (operation && operation.errors && operation.errors[field]) {
      operation.errors[field] = "";
    }
  }
  validateField(params) {
    const { colDef, data, newValue } = params;
    const field = colDef ? colDef.field : null;
    let errorKey = null;
    if (data && field) {
      switch (field) {
        case "payCode":
        case "description":
          if (
            doesEmpty(newValue) ||
            formvalidator.validateAlphaNumPunctuation(newValue)
          ) {
            errorKey = "xmm.portal.errors.invalid_field";
          }
          break;
        default:
          break;
      }
    }
    if (errorKey) {
      this.setFieldValidation(data, field, errorKey);
    }
    return !errorKey;
  }
  confirmAddPayTypes = SPISQSFeatureEnabled => {
    if (SPISQSFeatureEnabled) {
      this.addDefaultCDKPayTypes();
    } else {
      this.addDefaultPayTypes();
    }
  };
  addDefaultPayTypes = () => {
    this.updateStatusBox(this.savingMsg, "pending", false);
    const { dealerCode, user } = this.context;
    const { userName } = user;
    DealerTrackDefaultPayTypes.forEach((p, index) => {
      const data = { ...p, dealerCode, modUser: userName };
      const url =
        "/ops/proxyapi/ddsproxy/rest/table/dealerPayType?_method=post";
      // const url = "/ops/rest-db/addTableData/paytypes";
      makeSecureRestApi(
        {
          url,
          method: "post",
          data
        },
        response => {
          if (response && response.success) {
            const { dealerPayType } = response;
            if (index === 3)
              this.updateStatusBox(this.savedMsg, "success", true);
            // callback grid to add record
            this.updatePayTypeAfterSave(dealerPayType);
          }
          this.setState({
            showConfirmAddDefaultPayTypes: false
          });
        },
        error => {
          const msg = error["message"] ? error.message : this.saveErrorMsg;
          if (index === 3) this.updateStatusBox(msg, "error", false, true);
        }
      );
    });
  };
  addDefaultCDKPayTypes = () => {
    this.updateStatusBox(this.savingMsg, "pending", false);
    const { dealerCode } = this.context;
    const url = `/ops/proxyapi/catalogadminproxy/opsadmin/paytypes/generateDefaultCDKPayTypes?dealerCode=${dealerCode}`;
    makeSecureRestApi(
      {
        url,
        method: "get"
      },
      response => {
        if (response && response.success) {
          this.updateStatusBox(this.savedMsg, "success", true);
          this.loadGridData();
        }
        this.setState({
          showConfirmAddDefaultPayTypes: false
        });
      },
      error => {
        const msg = error["message"] ? error.message : this.saveErrorMsg;
      }
    );
  };
  // bind to add rate click event
  addActionSlider = event => {
    event.preventDefault();
    this.setState(prevState => ({
      showSlide: !prevState.showSlide,
      editOption: "add",
      rowRecord: PayTypeRecord,
      operationName: this.addPayTypeLabel
    }));
  };
  addOptionSlider = event => {
    event.preventDefault();
    this.setState(prevState => ({
      showSlide: !prevState.showSlide,
      editOption: "add",
      rowRecord: PayTypeRecord,
      operationName: this.option
    }));
  };
  handleRowSelected = () => {};

  /* This selection handler returns selected records from grid */
  handleSelectionChanged = () => {
    if (this.gridApi) {
      const selectedRows = this.gridApi.getSelectedRows();
      this.setState({ selectionlist: selectedRows });
    }
  };
  hideSlider = () => {
    this.setState(prevState => ({
      showSlide: !prevState.showSlide,
      editOption: "cancel",
      rowRecord: PayTypeRecord,
      operationName: this.addPayTypeLabel
    }));
  };
  /* Close Handler for Slider and click event outside the drawer
     use this context callback to show speed bump when Form is dirty
  */
  closeSlider = () => {
    const callback = () => {
      this.setState({
        showSlide: false
      });
    };
    const isDirtyFunc = () => {
      return this.payTypeFormRef.current.state.dirty;
    };
    if (this.context.discardUnsavedChanges({}, callback, isDirtyFunc)) {
      this.setState({
        showSlide: false
      });
    }
  };
  updatePayTypeAfterSave = payType => {
    if (this.gridApi && payType) {
      const res = this.gridApi.applyTransaction({
        add: [payType]
      });
      // get the row node with ID
      const rowNode = this.gridApi.getRowNode(payType.dealerPayTypeId);
      this.gridApi.ensureIndexVisible(res.add[0].rowIndex, "top");
      rowNode.setSelected(false);
      const gridParams = {
        force: true,
        rowNodes: [rowNode]
      };
      const { payTypes } = this.state;
      payTypes.push(payType);
      this.setState({ payTypes });
      // this.context.updateDealerLaborRates("add", payType);
      this.callRefreshAfterMillis(gridParams, this.gridApi);
    }
  };
  closeDeleteModal = doneDeleting => {
    if (doneDeleting) {
      this.setState({ selectionlist: [], showDeleteConfirmation: false });
    }
  };
  renderMoreButton(params) {
    const dealerPayType = params.data;
    // return <IconMore className="xmm-rule-dropdown-menu-renderer" />;
    return (
      <IconDelete
        className="xmm-rule-dropdown-menu-renderer"
        onClick={() => {
          this.confirmDeletePayType(dealerPayType);
        }}
      />
    );
  }
  renderDeletePayTypeConfirmation() {
    const { dealerPayType, showDeleteConfirmation } = this.state;
    const { localeStrings } = this.context;
    return showDeleteConfirmation ? (
      <Confirmation
        htmlId="deleteMenuType"
        message={localeStrings["xmm.portal.paytype.delete_confirmation"]}
        proceedButtonStyle="danger"
        show={showDeleteConfirmation}
        actionFunction={() => {
          this.handleDeletePayTypes(dealerPayType);
        }}
        closeDialog={this.closeDeleteModal}
      />
    ) : (
      ""
    );
  }
  showAddDefaultPayTypesModal = () => {
    this.setState({
      showConfirmAddDefaultPayTypes: true
    });
  };
  closeAddDefaultPayTypesModal = () => {
    this.setState({
      showConfirmAddDefaultPayTypes: false
    });
  };
  renderConfirmAddDefaultPayTypes() {
    const { showConfirmAddDefaultPayTypes } = this.state;
    const { SPISQSFeatureEnabled } = this.context;
    return showConfirmAddDefaultPayTypes ? (
      <Confirmation
        htmlId="deleteMenuType"
        message={
          SPISQSFeatureEnabled
            ? this.createCDKPayTypesInfo
            : this.createDefaultPayTypesInfo
        }
        proceedButtonStyle="danger"
        show={showConfirmAddDefaultPayTypes}
        actionFunction={() => {
          this.confirmAddPayTypes(SPISQSFeatureEnabled);
        }}
        closeDialog={this.closeAddDefaultPayTypesModal}
      />
    ) : (
      ""
    );
  }
  /* Handler to update statusbox state props */
  updateStatusBox(msg, type, close, errorInTooltip) {
    console.log("status", msg, type, close);
    const sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
    sleep(0).then(() => {
      this.setState({
        statusMsg: msg,
        autoClose: close,
        statusType: type,
        errorInTooltip
      });
    });
  }
  render() {
    const { getClassWithBridgeBar, SPISQSFeatureEnabled } = this.context;
    const deleteModal = this.renderDeletePayTypeConfirmation();
    const confirmAddDefaultPayTypes = this.renderConfirmAddDefaultPayTypes();
    const gridWidget = (
      <div id="grid-wrapper">
        <div
          id="mainGrid"
          className={getClassWithBridgeBar("ag-grid-container")}
        >
          <AgGridReact
            localeText={this.state.localeText}
            columnDefs={this.state.columnDefs}
            defaultColDef={this.state.defaultColDef}
            suppressRowClickSelection={true}
            suppressMenuHide={false}
            suppressContextMenu={true}
            rowData={this.state.payTypes}
            rowSelection={this.state.rowSelection}
            rowDeselection={true}
            singleClickEdit={true}
            stopEditingWhenCellsLoseFocus={true}
            animateRows={true}
            onGridReady={this.onGridReady}
            onGridSizeChanged={this.handleGridSizeChanged}
            loadingOverlayComponent={this.state.loadingOverlayComponent}
            loadingOverlayComponentParams={
              this.state.loadingOverlayComponentParams
            }
            noRowsOverlayComponent={this.state.noRowsOverlayComponent}
            noRowsOverlayComponentParams={
              this.state.noRowsOverlayComponentParams
            }
            statusBar={this.state.statusBar}
            components={this.state.components}
            onCellClicked={this.onCellClickedEvent}
            onCellValueChanged={this.onCellValueChanged}
            onColumnResized={this.handleColumnResized}
            getRowNodeId={this.getRowNodeId}
            onRowSelected={this.handleRowSelected}
            onSelectionChanged={this.handleSelectionChanged}
            sideBar={this.state.sideBar}
            columnTypes={this.state.columnTypes}
            multiSortKey={this.state.multiSortKey}
            enableRangeSelection={false}
            enableCellTextSelection={true}
            enableBrowserTooltips={true}
            // enterMovesDownAfterEdit={true}
            // enterMovesDown={true}
            // rowHeight={50}
            onFirstDataRendered={this.onFirstDataRendered}
          />
        </div>
      </div>
    );
    const statusHtml = this.state.statusMsg ? (
      <StatusBox
        htmlId="statusBox"
        type={this.state.statusType}
        autoClose={this.state.autoClose}
        linkHtml={null}
        message={this.state.statusMsg}
        // autoCloseTime={1500}
        errorInTooltip={this.state.errorInTooltip}
      />
    ) : (
      ""
    );
    const { isDealerTrack, payTypes } = this.state;
    const addDefaultPayTypesClass =
      payTypes &&
      payTypes.length === 0 &&
      (isDealerTrack || SPISQSFeatureEnabled)
        ? ""
        : "hide";
    const header = (
      <React.Fragment>
        <div className="content-header">
          <h3 className="xmm-main-title">{this.state.pageTitle}</h3>
          <div className="xmm-form-header">
            <div className="float-right">{statusHtml}</div>
            <Button
              htmlId="addDefaultPayTypesActionBtn"
              buttonStyle="primary"
              onClick={this.showAddDefaultPayTypesModal}
              className={addDefaultPayTypesClass}
            >
              {SPISQSFeatureEnabled ? this.addCDKPayTypesLabel : this.addDefaultPayTypesLabel}
            </Button>
            <Button
              htmlId="addActionBtn"
              buttonStyle="secondary"
              disabled={this.state.disableAction}
              onClick={this.addActionSlider}
              className="float-right"
            >
              {this.addPayTypeLabel}
            </Button>
            <div className="xmm-input-search float-right">
              <input
                type="text"
                id="paytype-search-box"
                className="xmm-input"
                placeholder={this.searchLabel}
                onChange={this.onSearchBoxChanged}
                value={this.state.searchKey}
                autoComplete="off"
              />
            </div>
          </div>
        </div>
      </React.Fragment>
    );
    // Add components within slider
    const modalTitle = <span>{this.state.operationName}</span>;
    const formSlider = (
      <GenericSlider
        title={modalTitle}
        htmlId="editCommonSlider"
        showSlide={this.state.showSlide}
        toggleSlider={this.closeSlider}
        sliderWidth={this.state.sliderWidth}
        flexWidth={this.state.flexWidth}
        className={getClassWithBridgeBar("xmm-slider-1")}
      >
        <div>
          <AddPayTypeFormPage
            key={"editSlider"}
            ref={this.payTypeFormRef}
            rowRecord={this.state.rowRecord}
            editForm={true}
            payTypes={this.state.payTypes}
            editOption={this.state.editOption}
            hideSlider={this.hideSlider}
            updatePayTypeAfterSave={this.updatePayTypeAfterSave}
          />
        </div>
      </GenericSlider>
    );
    return (
      <div className="xmm-center-container">
        {header}
        {gridWidget}
        {formSlider}
        {deleteModal}
        {confirmAddDefaultPayTypes}
      </div>
    );
  }
}

export default PayTypesPage;

PayTypesPage.propTypes = {};
